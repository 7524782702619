import { Component, OnInit , ViewChild, ElementRef, ViewEncapsulation, Injectable } from '@angular/core';
import { NgbCalendar, NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerModule, NgbDateStruct, NgbModal, NgbModalOptions, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe, formatDate, PlatformLocation } from '@angular/common';
import * as $ from 'jquery';
import { CommunicationService } from '../services/communication.service';
import { HttpErrorResponse } from '@angular/common/http';
import { InsuranceService } from '../services/insurance.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { CustomAdapter, CustomDateParserFormatter } from '../helpers/components/CalendarCustomDateParser.service';
import { InputValidationService } from '../helpers/validators/input-validation.service';
import { CookieService } from 'ngx-cookie-service';
import { UserService } from '../services/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-insurance',
  templateUrl: './insurance.component.html',
  styleUrls: ['./insurance.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [
		{ provide: NgbDateAdapter, useClass: CustomAdapter },
		{ provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
	],
})
export class InsuranceComponent implements OnInit {
  @ViewChild('readDisclosures', { static: false }) readDisclosures: ElementRef;
  @ViewChild('lifeDisclosures', { static: false }) lifeDisclosures: ElementRef;
  @ViewChild('funeralDisclosures', { static: false }) funeralDisclosures: ElementRef;
  @ViewChild('termPop', { static: false }) termPop: ElementRef;
  @ViewChild('mainInsuredDetail', { static: false }) mainInsuredDetail: ElementRef;
  @ViewChild('legalInsurence', { static: false }) legalInsurence: ElementRef;
  @ViewChild('legalDisclosures', { static: false }) legalDisclosures: ElementRef;
  @ViewChild('callbackConfirmationModel', { static: false }) callbackConfirmationModel: ElementRef;
  modalOptions: NgbModalOptions;
  creditLifeParam;lifeParam;funeralParam;legalParam;
  callbackErrorMsg = '';
  callbackSuccessMsg = '';
  campaignSourceToModel = '';
  hasCreditLifeCover = false; hasLifeCover = false; hasFuneralCover = false; hasLegalCover= false;
  isCreditLifeCoverAccepted = false; isLifeCoverAccepted = false; isLegalCoverAccepted= false; isFuneralCoverAccepted = false; state = '';
  firstName = ''; lastName = ''; idNumber = ''; birthDate = ''; occupation = ''; insurer='';
  beneficiaryFirstName = ''; beneficiaryLastName = ''; beneficiaryRelationship = ''; beneficiaryDateOfBirth = '';
  creditLifeCoverForm: FormGroup;
  lifeCoverForm: FormGroup;
  legalCoverForm:FormGroup;
  funeralCoverForm: FormGroup;
  successMsg = '';
  errorMsg = '';
  dobUpto: Date = new Date();
  readDisclosure = false; readTerms = false;
  disclosureHtml = '';
  termsAndConditionHtml = '';
  validationErrorMsg = 'Beneficiary information incomplete';
  toggleAcceptTerm = false;
  mainBeneficiaryAge; 
  acceptedDate: null; acceptanceMethod: null;
  spouseIdNumber:	null; spouseFirstName: null; spouseLastName: null;

  mainBeneficiaryDOBFromAPI;
  otherBeneficiaryDOBFromAPI;

  private fieldArray: Array<any> = [];
  private newAttribute: any = {};
  constructor(
    private location: PlatformLocation,
    private modalService: NgbModal,
    private communicationService: CommunicationService,
    private insuranceService: InsuranceService,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private cookieService: CookieService,
    private userService: UserService,
    private router: Router,
    private dateConfig: NgbDatepickerConfig, calendar: NgbCalendar

  ) {
      this.modalOptions = {
        backdrop: 'static',
        backdropClass: 'customBackdrop',
        keyboard: false
      };
      let currDate = new Date();
      location.onPopState(() => this.modalService.dismissAll());
      dateConfig.minDate = { year: currDate.getFullYear()-100, month: 1, day: 1 };
      dateConfig.maxDate = { year: currDate.getFullYear(),month: currDate.getMonth()+1, day: currDate.getDate()  };
      dateConfig.dayTemplate
      // days that don't belong to current month are not visible
      dateConfig.outsideDays = 'hidden';
  }

  beneficiaryOthersForm():FormGroup {
    return this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      birthDate: ['', Validators.required],
      gender: ['', Validators.required],
      mainClientIDNumber: [''],
      relationship: ['', Validators.required],
      sharePercentage: [''],
      isMainBeneficiary: [false],
      age: ['']
    })
  }

  ngOnInit() {
    this.loadUserType((userType) => {
      if (userType != '1') {
        this.router.navigate(['/portal/dashboard']);
      }
    });
    this.dobUpto.setFullYear(this.dobUpto.getFullYear()-18);
    this.getPolicyHolderDetails();

    this.creditLifeCoverForm = this.formBuilder.group({
      leadID: [''],
      idNumber: [''],
      firstName: [''],
      lastName: [''],
      dob: [''],
      state: [''],
      negotiator: [''],
      acceptedDate: [''],
      agreementSentDate: [''],
      hasCreditLife: [''],
      hasLifeInsurance: [''],
      hasLegal:[''],
      hasFuneral: [''],
      policyNumber: [''],
      coverAmount: [''],
      rehabCovered: [''],
      creditLifePremium: [''],
      retrenchmentPremium: [''],
      totalPremium: [''],
      effectiveDate: [''],
      coveredAccounts: [],
      beneficiaryFirstName: ['', Validators.required],
      beneficiaryLastName: ['', Validators.required],
      beneficiaryDateOfBirth: ['',[ Validators.required, InputValidationService.isValidDob, InputValidationService.minimumAgeValidator]],
      beneficiaryGender: [''],
      mainClientIDNumber: [''],
      beneficiaryRelationship: ['', Validators.required],
      isMainBeneficiary: [false],
      acceptTerms: [false, Validators.pattern('true')],
      readTerms: [false, Validators.pattern('true')]
    });

    this.lifeCoverForm = this.formBuilder.group({
      leadID: [''],
      idNumber: [''],
      firstName: [''],
      lastName: [''],
      dob: [''],
      state: [''],
      negotiator: [''],
      acceptedDate: [''],
      agreementSentDate: [''],
      hasCreditLife: [''],
      hasLifeInsurance: [''],
      hasLegal:[''],
      hasFuneral: [''],
      clientToComplete: [''],
      policyNumber: [''],
      coverAmount: [''],
      premium: [''],
      permanentDisabilityAmount: [''],
      dreadDiseaseAmount: [''],
      lifeInsuranceCreatedBy: [''],
      beneficiaryFirstName: ['', Validators.required],
      beneficiaryLastName: ['', Validators.required],
      beneficiaryDateOfBirth: ['',[ Validators.required, InputValidationService.isValidDob, InputValidationService.minimumAgeValidator]],
      beneficiaryGender: [''],
      mainClientIDNumber: [''],
      beneficiaryRelationship: ['', Validators.required],
      sharePercentage: [''],
      isMainBeneficiary: [false],
      acceptTerms: [false, Validators.pattern('true')],
      readTerms: [false, Validators.pattern('true')]
    });

    this.legalCoverForm =this.formBuilder.group({
      leadID: [''],
      idNumber: [''],
      spouseIdNumber: [''],
      spouseDOB:[''],
      spouseFirstName: [''],
      spouseLastName: [''],
      hasCreditLife: [''],
      hasLifeInsurance: [''],
      hasLegal:[''],
      hasFuneral: [''],
      policyNumber: [''],
      premium: [''],
      criminalChargesAmount:[''],
      civilActionAmount: [''],
      labourMattersAmount:[''],
      maximumBenefitAmountPerInsuredMatter:[''],
      annualLimit:[''],
      overallBenefitLimit:[''],
      planOption: [''],
      clientToComplete:[''],
      acceptTerms: [false, Validators.pattern('true')],
      readTerms: [false, Validators.pattern('true')]
    });

    this.funeralCoverForm = this.formBuilder.group({
      leadID: [''],
      idNumber: [''],
      firstName: [''],
      lastName: [''],
      dob: [''],
      state: [''],
      negotiator: [''],
      acceptedDate: [''],
      agreementSentDate: [''],
      hasCreditLife: [''],
      hasLifeInsurance: [''],
      hasLegal:[''],
      hasFuneral: [''],
      clientToComplete: [''],
      funeralScheme:  [''],
      policyNumber: [''],
      coverAmount: [''],
      premium: [''],
      funeralDate: [''],
      funeralFirstPayDate: [''],
      peopleCovered: [''],
      ageOfOldest: [''],
      beneficiaryFirstName: ['', Validators.required],
      beneficiaryFirstNameExist: [false],
      beneficiaryLastName: ['', Validators.required],
      beneficiaryLastNameExist: [false],
      beneficiaryDateOfBirth: ['', [ Validators.required,InputValidationService.isValidDob, InputValidationService.minimumAgeValidator]],
      beneficiaryDateOfBirthExist: [false],
      beneficiaryGender: ['', Validators.required],
      beneficiaryGenderExist: [false],
      mainClientIDNumber: [''],
      beneficiaryRelationship: ['', Validators.required],
      beneficiaryRelationshipExist: [false],
      sharePercentage: [''],
      isMainBeneficiary: [true],
      acceptTerms: [false, Validators.pattern('true')],
      readTerms: [false, Validators.pattern('true')],
      beneficiaryOthers: this.formBuilder.array([]),
      beneficiaryOthersCount: [0],
      beneficiaryAge: ['']
    });
  }

  loadUserType(callback: (userType: string) => void) {
    let type = this.cookieService.get('uType');
    if (type && type != '') {
      callback(type);
    } else {
      this.userService.getUserType().subscribe(
        (data: any) => {
          this.cookieService.set('uType', data, null, '/', undefined, true);
          callback(this.cookieService.get('uType'));
        },
        (err: HttpErrorResponse) => {
          callback('2');
        }
      );
    }
  }

  get creditLifeCoverFormControls() { return this.creditLifeCoverForm.controls; }
  get lifeCoverFormControls() { return this.lifeCoverForm.controls; }
  get legalCoverFormControls() { return this.legalCoverForm.controls; }
  get funeralCoverFormControls() { return this.funeralCoverForm.controls; }

  get beneficiaryOthersFormControls(): FormArray{
    return <FormArray> this.funeralCoverForm.get('beneficiaryOthers');
  }

  private scrollToTop() {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }


  getPolicyHolderDetails() {
    this.insuranceService.getPolicyHolderDetails().subscribe((data: any) => {
      this.firstName = data.firstName;
      this.lastName = data.lastName;
      this.birthDate = data.birthDate;
      this.idNumber = data.idNumber; 
      this.birthDate = data.birthDate; 
      this.insurer = data.insurer;
      this.occupation = data.occupation;
      this.hasCreditLifeCover = data.hasCreditLifeCover;
      this.hasLifeCover = data.hasLifeCover;
      this.hasFuneralCover = data.hasFuneralCover;
      this.hasLegalCover = data.hasLegalCover;
      this.state = data.state;
      this.beneficiaryFirstName = data.beneficiaryFirstName;
      this.beneficiaryLastName = data.beneficiaryLastName;
      this.beneficiaryRelationship = data.beneficiaryRelationship;
      this.beneficiaryDateOfBirth = data.beneficiaryDateOfBirth;
      this.isCreditLifeCoverAccepted = data.isCreditLifeCoverAccepted;
      this.isLifeCoverAccepted = data.isLifeCoverAccepted;
      this.isFuneralCoverAccepted = data.isFuneralCoverAccepted;
      this.isLegalCoverAccepted = data.isLegalCoverAccepted
      this.acceptedDate = data.acceptedDate;
      this.acceptanceMethod = data.acceptanceMethod;
      this.spouseIdNumber =	data.spouseIdNumber;
      this.spouseFirstName = data.spouseFirstName; 
      this.spouseLastName = data.spouseLastName;

      sessionStorage.setItem('loadPolicyHolderDetails', JSON.stringify(data));
    });
  }

  getInsurancePolicyDetails(insuranceTypeId: number, callback) {
    const param = { InsuranceTypeId: insuranceTypeId };
    this.insuranceService.getInsurancePolicyDetails(param).subscribe((data: any) => {
      if(data != null) {
        console.log(data.policyDetailsHtml);
        this.disclosureHtml = data.policyDetailsHtml;
        this.termsAndConditionHtml = data.termsAndConditionHtml;

        if (callback) {
          callback();
        }
      }
    }, (err: HttpErrorResponse) => {

    });
  }

  updatePolicyDetailValues(insuranceTypeId: number) {
    if(insuranceTypeId == 1) {
      var coveredAccountHtml = '<div class="table-responsive"> <table class="table table-sm"> <tbody>';
      for(let coveredAccount of this.creditLifeCoverFormControls.coveredAccounts.value) {
        coveredAccountHtml += '<tr><td>' + coveredAccount.creditor + '</td>' + 
        '<td>' + coveredAccount.accountNumber + '</td>' +
        '<td>' + coveredAccount.currentBalance + '</td>' +
        '<td>' + coveredAccount.currentInstallment + '</td></tr>';
      }
      coveredAccountHtml += '</tbody> </table> </div>';
      this.disclosureHtml = this.disclosureHtml.replace('#CoverAmount#', this.creditLifeCoverFormControls.coverAmount.value)
                              .replace('#RehabCovered#', this.creditLifeCoverFormControls.rehabCovered.value)
                              .replace('#BeneficiaryFirstName#', this.creditLifeCoverFormControls.beneficiaryFirstName.value)
                              .replace('#BeneficiaryLastName#', this.creditLifeCoverFormControls.beneficiaryLastName.value)
                              .replace('#BeneficiaryRelationship#', this.creditLifeCoverFormControls.beneficiaryRelationship.value)
                              .replace('#BeneficiaryDateOfBirth#', this.datePipe.transform(this.creditLifeCoverFormControls.beneficiaryDateOfBirth.value))
                              .replace('#PolicyHolderName#', this.creditLifeCoverFormControls.firstName.value + ' ' + this.creditLifeCoverFormControls.lastName.value)
                              .replace('#PolicyHolderIdNumber#', this.creditLifeCoverFormControls.idNumber.value)
                              .replace('#Negotiator#', this.creditLifeCoverFormControls.negotiator.value)
                              .replace('#CoveredAccount#', coveredAccountHtml);

    } else if(insuranceTypeId == 2) {
      this.disclosureHtml = this.disclosureHtml.replace('#CoverAmount#', this.lifeCoverFormControls.coverAmount.value)
                              .replace('#PermanentDisabilityAmount#', this.lifeCoverFormControls.permanentDisabilityAmount.value)
                              .replace('#DreadDiseaseAmount#', this.lifeCoverFormControls.dreadDiseaseAmount.value)
                              .replace('#Premium#', this.lifeCoverFormControls.premium.value)
                              .replace('#PolicyHolderName#', this.lifeCoverFormControls.firstName.value + ' ' + this.lifeCoverFormControls.lastName.value)
                              .replace('#PolicyHolderIdNumber#', this.lifeCoverFormControls.idNumber.value)
                              .replace('#Negotiator#', this.lifeCoverFormControls.negotiator.value);


    } else if(insuranceTypeId == 3) {
      var otherInsuredLivesHtml = '';

      for(let beneficiaryOther of this.funeralCoverFormControls.beneficiaryOthers.value) {
        otherInsuredLivesHtml += '<tr><td>' + beneficiaryOther.firstName + '</td>' +
          '<td>' + beneficiaryOther.lastName + '</td>' +
          '<td>' + beneficiaryOther.relationship + '</td>' +
          '<td>' + this.datePipe.transform(beneficiaryOther.birthDate) + '</td>' +
          '<td>' + this.calculateAge(beneficiaryOther.birthDate) + '</td>' +
          '<td>' + beneficiaryOther.gender + '</td>' +
          '<td>R ' + this.funeralCoverFormControls.coverAmount.value + '</td>';
          '<td>' + beneficiaryOther.age + '</td></tr>';
      }

      this.disclosureHtml = this.disclosureHtml.replace('#FuneralScheme#', this.funeralCoverFormControls.funeralScheme.value)
                              .replace('#PeopleCovered#', this.funeralCoverFormControls.peopleCovered.value)
                              .replace('#BeneficiaryFirstName#', this.funeralCoverFormControls.beneficiaryFirstName.value)
                              .replace('#BeneficiaryLastName#', this.funeralCoverFormControls.beneficiaryLastName.value)
                              .replace('#BeneficiaryRelationship#', this.funeralCoverFormControls.beneficiaryRelationship.value)
                              .replace('#BeneficiaryDateOfBirth#', this.datePipe.transform(this.funeralCoverFormControls.beneficiaryDateOfBirth.value))
                              .replace('#BeneficiaryAge#', this.calculateAge(this.funeralCoverFormControls.beneficiaryDateOfBirth.value).toString())
                              .replace('#BeneficiaryGender#', this.funeralCoverFormControls.beneficiaryGender.value)
                              .replace('#CoverAmount#', this.funeralCoverFormControls.coverAmount.value)
                              .replace('#Premium#', this.funeralCoverFormControls.premium.value)
                              .replace('#PolicyHolderName#', this.funeralCoverFormControls.firstName.value + ' ' + this.funeralCoverFormControls.lastName.value)
                              .replace('#PolicyHolderIdNumber#', this.funeralCoverFormControls.idNumber.value)
                              .replace('#Negotiator#', this.funeralCoverFormControls.negotiator.value)
                              .replace('#OtherInsuredLives#', otherInsuredLivesHtml);
                              
    }
      else if(insuranceTypeId == 4) {
        this.disclosureHtml = this.disclosureHtml.replace('#AnnualLimit#', this.legalCoverFormControls.annualLimit.value)
                                .replace('#OverallBenefitLimit#', this.legalCoverFormControls.overallBenefitLimit.value)
                                .replace('#SpouseIdNumber#', this.legalCoverFormControls.spouseIdNumber.value)
                                .replace('#Premium#', this.legalCoverFormControls.premium.value)
                                .replace('#Premium#', this.legalCoverFormControls.premium.value)
                                .replace('#SpouseFullName#', this.legalCoverFormControls.spouseFirstName.value + ' ' + this.legalCoverFormControls.spouseLastName.value)
                                .replace('#SpouseDOB#', this.legalCoverFormControls.spouseDOB.value)
                              
      }

  }
  
  creditLifeForm(){
    this.successMsg = this.errorMsg = "";
    this.readDisclosure = this.readTerms = false;

    this.insuranceService.getInsuranceDetails().subscribe((data: any) => {

      this.creditLifeCoverForm.patchValue({
        leadID: data.leadID,
        idNumber: data.idNumber,
        firstName: data.firstName,
        lastName: data.lastName,
        dob: data.dob,
        state: data.state,
        negotiator: data.negotiator,
        acceptedDate: data.acceptedDate,
        agreementSentDate: data.agreementSentDate,
        hasCreditLife: data.hasCreditLife,
        hasLegal:data.hasLegal,
        hasLifeInsurance: data.hasLifeInsurance,
        hasFuneral: data.hasFuneral,
        policyNumber: data.creditLife.policyNumber,
        coverAmount: data.creditLife.coverAmount,
        rehabCovered: data.creditLife.rehabCovered,
        creditLifePremium: data.creditLife.creditLifePremium,
        retrenchmentPremium: data.creditLife.retrenchmentPremium,
        totalPremium: data.creditLife.totalPremium,
        effectiveDate: data.creditLife.effectiveDate,
        coveredAccounts: data.creditLife.coveredAccounts,
        readTerms: false
      });

      if(data.creditLife.creditLifeBeneficiary.length > 0) {
        this.creditLifeCoverForm.patchValue({
          beneficiaryFirstName: data.creditLife.creditLifeBeneficiary[0].firstName,
          beneficiaryLastName: data.creditLife.creditLifeBeneficiary[0].lastName,
          beneficiaryDateOfBirth: data.creditLife.creditLifeBeneficiary[0].birthDate,
          beneficiaryGender: data.creditLife.creditLifeBeneficiary[0].gender,
          mainClientIDNumber: data.creditLife.creditLifeBeneficiary[0].mainClientIDNumber,
          beneficiaryRelationship: data.creditLife.creditLifeBeneficiary[0].relationship,
          isMainBeneficiary: data.creditLife.creditLifeBeneficiary[0].isMainBeneficiary
        });
      }

      if(this.isCreditLifeCoverAccepted) {
        this.creditLifeCoverForm.patchValue({
          readTerms: true,
          acceptTerms: true
        });

        const param = { InsuranceTypeId: 1 };
        this.insuranceService.getUserBeneficiaryDetails(param).subscribe((data: any) => {
          if(data != null) {
            this.creditLifeCoverForm.patchValue({
              beneficiaryFirstName: data[0].beneficiaryFirstName,
              beneficiaryLastName: data[0].beneficiaryLastName,
              beneficiaryDateOfBirth:  this.getDOB(data.funeralBeneficiaryMain.birthDate),
              beneficiaryGender: data[0].beneficiaryGender,
              beneficiaryRelationship: data[0].beneficiaryRelationship,
              isMainBeneficiary: data[0].isMainBeneficiary,
            });
          }
        });
        
      }

      this.creditLifeParam = true;
      this.lifeParam = false;
      this.funeralParam = false;
      this.legalParam = false;
      $('.commonhold').hide();

    }, (err: HttpErrorResponse) => {

    });

    this.scrollToTop();
  }

  submitCreditLifeCover() {
    this.validationErrorMsg = '';
    this.successMsg = this.errorMsg = "";
    if (this.creditLifeCoverForm.invalid) {     
      this.validationErrorMsg = 'Beneficiary information incomplete'; 
      this.creditLifeCoverForm.markAllAsTouched();
      return;
    }
    this.insuranceService.saveCreditLifeCover(this.creditLifeCoverForm.value).subscribe((data: any) => {
      sessionStorage.removeItem('loadPolicyHolderDetails');
      this.successMsg = 'Credit life cover accepted successfully.';
      $('.commonhold').show();
      this.creditLifeParam = false;
      this.isCreditLifeCoverAccepted = true;
      this.getPolicyHolderDetails();
      
    }, (err: HttpErrorResponse) => {
      if(err.status === 409){
        this.errorMsg = "Credit life cover already accepted!";
      }else {
        this.errorMsg = err.error;
        console.log("--------------------Error-----------------",err.error);
      }  
    });

    this.scrollToTop();    
  }

  lifeForm(){
    this.successMsg = this.errorMsg = '';
    this.readDisclosure = this.readTerms = false;

    this.insuranceService.getInsuranceDetails().subscribe((data: any) => {

      this.lifeCoverForm.patchValue({
        leadID: data.leadID,
        idNumber: data.idNumber,
        firstName: data.firstName,
        lastName: data.lastName,
        dob: data.dob,
        state: data.state,
        negotiator: data.negotiator,
        acceptedDate: data.acceptedDate,
        agreementSentDate: data.agreementSentDate,
        hasCreditLife: data.hasCreditLife,
        hasLifeInsurance: data.hasLifeInsurance,
        hasLegal:data.hasLegal,
        hasFuneral: data.hasFuneral,
        clientToComplete: data.lifeInsurance.clientToComplete,
        policyNumber: data.lifeInsurance.policyNumber,
        coverAmount: data.lifeInsurance.coverAmount,
        premium: data.lifeInsurance.premium,
        permanentDisabilityAmount: data.lifeInsurance.permanentDisabilityAmount,
        dreadDiseaseAmount: data.lifeInsurance.dreadDiseaseAmount,
        lifeInsuranceCreatedBy: data.lifeInsurance.createdBy,
        readTerms: false
      });

      if(data.lifeInsurance.lifeBeneficiary.length > 0)
      {
        this.lifeCoverForm.patchValue({
          beneficiaryFirstName: data.lifeInsurance.lifeBeneficiary[0].firstName,
          beneficiaryLastName: data.lifeInsurance.lifeBeneficiary[0].lastName,
          beneficiaryDateOfBirth: data.lifeInsurance.lifeBeneficiary[0].birthDate,
          beneficiaryGender: data.lifeInsurance.lifeBeneficiary[0].gender,
          mainClientIDNumber: data.lifeInsurance.lifeBeneficiary[0].mainClientIDNumber,
          beneficiaryRelationship: data.lifeInsurance.lifeBeneficiary[0].relationship,
          sharePercentage: data.lifeInsurance.lifeBeneficiary[0].sharePercentage,
          isMainBeneficiary: data.lifeInsurance.lifeBeneficiary[0].isMainBeneficiary,
        });
      }

      if(this.isLifeCoverAccepted) {
        this.lifeCoverForm.patchValue({
          readTerms: true,
          acceptTerms: true
        });

        const param = { InsuranceTypeId: 2 };
        this.insuranceService.getUserBeneficiaryDetails(param).subscribe((data: any) => {
          if(data != null) {
            this.lifeCoverForm.patchValue({
              beneficiaryFirstName: data[0].beneficiaryFirstName,
              beneficiaryLastName: data[0].beneficiaryLastName,
              beneficiaryDateOfBirth: formatDate(data[0].beneficiaryDateOfBirth, 'yyyy/MM/dd', 'en-US'),
              beneficiaryGender: data[0].beneficiaryGender,
              beneficiaryRelationship: data[0].beneficiaryRelationship,
              isMainBeneficiary: data[0].isMainBeneficiary,
            });
          }
        });
        
      }
      
      this.creditLifeParam = false;
      this.lifeParam = true;
      this.funeralParam = false;
      this.legalParam = false;
      $('.commonhold').hide();

    }, (err: HttpErrorResponse) => {

    });

    

    this.scrollToTop();
  }

  legalForm() {
    this.successMsg = this.errorMsg = "";
    this.readDisclosure = this.readTerms = false;
    this.insuranceService.getInsuranceDetails().subscribe((data: any) => {
      if(data.legal !=null)
      {
        this.legalCoverForm.patchValue({
          leadID: data.leadID,
          idNumber: data.idNumber,
          spouseIdNumber: data.spouseIdNumber,
          spouseFirstName: data.spouseFirstName,
          spouseLastName: data.spouseLastName,
          hasCreditLife: data.hasCreditLife,
          hasLifeInsurance: data.hasLifeInsurance,
          hasFuneral: data.hasFuneral,
          policyNumber: data.legal.policyNumber,
          premium: data.legal.premium,
          criminalChargesAmount: data.legal.criminalChargesAmount,
          civilActionAmount: data.legal.civilActionAmount,
          labourMattersAmount: data.legal.labourMattersAmount,
          maximumBenefitAmountPerInsuredMatter: data.legal.maximumBenefitAmountPerInsuredMatter,
          annualLimit: data.legal.annualLimit,
          overallBenefitLimit: data.legal.overallBenefitLimit,
          planOption: data.legal.planOption,
          hasLegal: data.hasLegal,
          clientToComplete: data.lifeInsurance.clientToComplete,
          readTerms: false,
          spouseDOB: data.spouseIdNumber ? this.idToDobFormat(data.spouseIdNumber.substring(0, 6)) : null, 
        });
      }


      if (this.isLegalCoverAccepted) {
        this.legalCoverForm.patchValue({
          readTerms: true,
          acceptTerms: true,
        });
      }
  
      this.creditLifeParam = false;
      this.lifeParam = false;
      this.funeralParam = false;
      this.legalParam = true;
      $('.commonhold').hide();
      
      this.scrollToTop(); // Move this line inside the subscribe block
    }, (err: HttpErrorResponse) => {
      // Handle errors here
    });
  }
    
  submitLegalCover() {
    this.successMsg = this.errorMsg = "";
    if (this.legalCoverForm.invalid) {
      this.legalCoverForm.markAllAsTouched();
      return;
    }
     
    this.insuranceService.saveLegalCover(this.legalCoverForm.value).subscribe((data: any) => {
      this.successMsg = 'Legal cover accepted successfully.';
      $('.commonhold').show();
      this.legalParam = false;
      this.isLegalCoverAccepted = true;
    }, (err: HttpErrorResponse) => {
      if (err.status === 409) {
        this.errorMsg = "Legal cover already accepted!";
      } else {
        this.errorMsg = err.error;
        console.log("--------------------Error-----------------", err.error);
      }
    });
  
    this.scrollToTop();
  }
  idToDobFormat(id: string): string {
    const year = id.slice(0, 2);
    const month = id.slice(2, 4);
    const day = id.slice(4, 6);
  
    return `${year}/${month}/${day}`;
  }
  
  getMinDOBFromAge(age: any)
  {
    var todaysDate =  moment(new Date()).format('YYYY/MM/DD'); 
    var minyear, minmonth, minday;
    var todaysDateSplit = todaysDate.split("/");
    minyear = Number(todaysDateSplit[0])-age-1;
    minmonth = todaysDateSplit[1];
    minday =  Number(todaysDateSplit[2]);

    return  new Date(minyear+"/"+minmonth+"/"+minday);
  }


  getAge(age: any,dob: any){
    if(age != null)
      return age;
    else if(dob != null)
      return this.calculateAge(dob);
    else
      return '';
  }




  change(beneficiaryAge){ 
    if(beneficiaryAge === 'mainBeneficiaryDateOfBirth')
    {
      var mainbeneficiaryAge = this.mainBeneficiaryAge;
      var mainbeneficiaryDateOfBirth = this.funeralCoverForm.get('beneficiaryDateOfBirth');
   
      if(mainbeneficiaryAge != null && mainbeneficiaryAge != '' && mainbeneficiaryAge != 0)
      {
        var minDOBFromAge =  moment(this.getMinDOBFromAge(mainbeneficiaryAge)).format('YYYY/MM/DD');
        
        if(minDOBFromAge >= mainbeneficiaryDateOfBirth.value)
        {
          this.funeralCoverForm.controls['beneficiaryDateOfBirth'].setErrors({ 
            incorrect: true, 
            message: 'Date of birth cannot be before ' +  moment(minDOBFromAge).format('DD/MM/YYYY'), 
            maxDOB: false });   
          this.funeralCoverFormControls.beneficiaryDateOfBirth.errors;
        }
        else
        {
            this.mainBeneficarySetNull(mainbeneficiaryDateOfBirth.value);
        }
      }
      else
      {
        this.mainBeneficarySetNull(mainbeneficiaryDateOfBirth.value);
      }

    }else{
  
      var id = beneficiaryAge.split("-")[1];  
      var otherBeneficiaryAge = this.funeralCoverForm.get('beneficiaryOthers.'+id+'.age').value;
      var otherbeneficiaryDateOfBirth = this.funeralCoverForm.get('beneficiaryOthers.'+id+'.birthDate');
      
      if(otherBeneficiaryAge != null && otherBeneficiaryAge != '' && otherBeneficiaryAge != 0)
      {
        var minDOBFromAge =  moment(this.getMinDOBFromAge(otherBeneficiaryAge)).format('YYYY/MM/DD');

          if(minDOBFromAge >= otherbeneficiaryDateOfBirth.value)
          {
            console.log('date2 is Greater than date1');
            otherbeneficiaryDateOfBirth.markAsTouched();
            otherbeneficiaryDateOfBirth.setErrors({ 
              incorrect: true,
              message: "Date of birth cannot be before " + moment(minDOBFromAge).format('DD/MM/YYYY') });
          }
          else
          {
            this.otherBeneficarySetNull(otherbeneficiaryDateOfBirth.value, id);
          }
      }else{
          this.otherBeneficarySetNull(otherbeneficiaryDateOfBirth.value, id);
      }

    }

  }

  mainBeneficarySetNull(mainbeneficiaryDateOfBirth)
  {
    var mainAmainbeneficiaryAge = this.calculateAge(mainbeneficiaryDateOfBirth);
    this.funeralCoverForm.controls.beneficiaryAge.setValue(mainAmainbeneficiaryAge);
    const beneficiaryDateOfBirthControl = this.funeralCoverForm.get('beneficiaryDateOfBirth');
    if (!beneficiaryDateOfBirthControl.hasError('underAge')) {
      this.funeralCoverForm.controls['beneficiaryDateOfBirth'].setErrors(null);
    } 
  }


  otherBeneficarySetNull(othermainbeneficiaryDateOfBirth, id)
  {
    var bAge = this.calculateAge(othermainbeneficiaryDateOfBirth);
    this.funeralCoverForm.get('beneficiaryOthers.'+id+'.age').setValue(bAge);
    this.funeralCoverForm.get('beneficiaryOthers.'+id+'.birthDate').setErrors(null);
  }


  submitLifeCover() {
    this.validationErrorMsg = '';
    this.successMsg = this.errorMsg = '';
    if (this.lifeCoverForm.invalid) {
      this.validationErrorMsg = 'Beneficiary information incomplete'; 
      this.lifeCoverForm.markAllAsTouched();
      return;
    }

    this.insuranceService.saveLifeCover(this.lifeCoverForm.value).subscribe((data: any) => {
      sessionStorage.removeItem('loadPolicyHolderDetails');
      this.successMsg = 'Life cover accepted successfully.';
      $('.commonhold').show();
      this.lifeParam = false;
      this.isLifeCoverAccepted = true;
      
    }, (err: HttpErrorResponse) => {
      if(err.status === 409){
        this.errorMsg = "Life cover already accepted!"
      }else {
        this.errorMsg = err.error;
      }  
    });

    this.scrollToTop();
  }

  getDOB(dob){
    if(dob != null) 
      return formatDate(dob, 'yyyy/MM/dd', 'en-US');
    return '';
  }

  funeralForm(){
    this.successMsg = this.errorMsg = "";
    this.readDisclosure = this.readTerms = false;

    this.insuranceService.getFuneralCoverDetails().subscribe((data: any) => {

      this.funeralCoverForm.patchValue({
        leadID: data.insuranceDetails.leadID,
        idNumber: data.insuranceDetails.idNumber,
        firstName: data.insuranceDetails.firstName,
        lastName: data.insuranceDetails.lastName,
        dob: data.insuranceDetails.dob,
        state: data.insuranceDetails.state,
        negotiator: data.insuranceDetails.negotiator,
        acceptedDate: data.insuranceDetails.acceptedDate,
        agreementSentDate: data.insuranceDetails.agreementSentDate,
        hasCreditLife: data.insuranceDetails.hasCreditLife,
        hasLifeInsurance: data.insuranceDetails.hasLifeInsurance,
        hasLegal:data.insuranceDetails.hasLegal,
        hasFuneral: data.insuranceDetails.hasFuneral,
        clientToComplete: data.insuranceDetails.funeral.clientToComplete,
        funeralScheme: data.insuranceDetails.funeral.funeralScheme,
        policyNumber: data.insuranceDetails.funeral.policyNumber,
        coverAmount: data.insuranceDetails.funeral.coverAmount,
        premium: data.insuranceDetails.funeral.premium,
        funeralDate: data.insuranceDetails.funeral.funeralDate,
        funeralFirstPayDate: data.insuranceDetails.funeral.funeralFirstPayDate,
        peopleCovered: data.insuranceDetails.funeral.peopleCovered,
        ageOfOldest: data.insuranceDetails.funeral.ageOfOldest,
        beneficiaryOthersCount: data.funeralBeneficiaryOtherCount == null ? 0 : data.funeralBeneficiaryOtherCount,
        readTerms: false,
      });


    var ddd =  this.getDOB(data.funeralBeneficiaryMain.birthDate);
      

      if(data.funeralBeneficiaryMain != null) {
        this.funeralCoverForm.patchValue({
          beneficiaryFirstName: data.funeralBeneficiaryMain.firstName,
          beneficiaryFirstNameExist: data.funeralBeneficiaryMain.firstName != '',
          beneficiaryLastName: data.funeralBeneficiaryMain.lastName,
          beneficiaryLastNameExist: data.funeralBeneficiaryMain.lastName != '',
          beneficiaryDateOfBirth:  this.getDOB(data.funeralBeneficiaryMain.birthDate),
          beneficiaryDateOfBirthExist: data.funeralBeneficiaryMain.birthDate != '',
          beneficiaryGender: data.funeralBeneficiaryMain.gender,
          beneficiaryGenderExist: data.funeralBeneficiaryMain.gender != '',
          mainClientIDNumber: data.funeralBeneficiaryMain.mainClientIDNumber,
          beneficiaryRelationship: data.funeralBeneficiaryMain.relationship,
          beneficiaryRelationshipExist: data.funeralBeneficiaryMain.relationship != '',
          sharePercentage: data.funeralBeneficiaryMain.sharePercentage,
          isMainBeneficiary: data.funeralBeneficiaryMain.isMainBeneficiary,
          beneficiaryAge: this.getAge(data.funeralBeneficiaryMain.age,data.funeralBeneficiaryMain.birthDate)
        });

        this.mainBeneficiaryAge = this.getAge(data.funeralBeneficiaryMain.age,data.funeralBeneficiaryMain.birthDate);
        this.mainBeneficiaryDOBFromAPI = this.getDOB(data.funeralBeneficiaryMain.birthDate);
      }
  

      if(data.funeralBeneficiaryOtherCount > 0 && data.insuranceDetails.funeral.peopleCovered > 1) {
        for(let val of data.funeralBeneficiaryOther) {
          this.beneficiaryOthersFormControls.push(this.formBuilder.group({
            firstName: [val.firstName, Validators.required],
            lastName: [val.lastName, Validators.required],
            birthDate: [this.getDOB(val.birthDate), Validators.required],
            gender: [val.gender, Validators.required],
            mainClientIDNumber: [val.mainClientIDNumber],
            relationship: [val.relationship, Validators.required],
            sharePercentage: [val.sharePercentage],
            isMainBeneficiary: [val.isMainBeneficiary],
            age: [this.getAge(val.age, val.birthDate)]
          }));
        }
        this.otherBeneficiaryDOBFromAPI = data.funeralBeneficiaryOther;
      }


      if(this.isFuneralCoverAccepted) {        
      
        this.funeralCoverForm.patchValue({
          readTerms: true,
          acceptTerms: true
        });

        const param = { InsuranceTypeId: 3 };
        this.insuranceService.getUserBeneficiaryDetails(param).subscribe((data: any) => {
          if(data != null) {
            this.funeralCoverForm.patchValue({
              beneficiaryFirstName: data[0].beneficiaryFirstName,
              beneficiaryLastName: data[0].beneficiaryLastName,
              beneficiaryDateOfBirth: data[0].beneficiaryDateOfBirth,
              beneficiaryGender: data[0].beneficiaryGender,
              beneficiaryRelationship: data[0].beneficiaryRelationship,
              isMainBeneficiary: data[0].isMainBeneficiary,
              beneficiaryAge: data[0].beneficiaryAge,
            });

            let i = 1;
            this.beneficiaryOthersFormControls.clear();
            for(let val of data) {
              if(i++ == 1) {continue;}
              this.beneficiaryOthersFormControls.push(this.formBuilder.group({
                firstName: [val.beneficiaryFirstName],
                lastName: [val.beneficiaryLastName],
                birthDate: [val.beneficiaryDateOfBirth],
                gender: [val.beneficiaryGender],
                relationship: [val.beneficiaryRelationship],
                isMainBeneficiary: [val.isMainBeneficiary],
                age: [val.beneficiaryAge]
              }));
            }

          }
        });
        
      }
      
      this.creditLifeParam = false;
      this.lifeParam = false;
      this.funeralParam = true;
      this.legalParam = false;
      $('.commonhold').hide();

    }, (err: HttpErrorResponse) => {

    });

    this.scrollToTop();
  }

  addFieldValue() {
    this.beneficiaryOthersFormControls.push(this.beneficiaryOthersForm());
  }

  deleteFieldValue(index) {
      this.fieldArray.splice(index, 1);
  }

  enableAcceptTerm(){
    this.toggleAcceptTerm = false;
  }
  disableAcceptTerm(){
    this.toggleAcceptTerm = true;
  }
  

  submitFuneralCover() {
    this.successMsg = this.errorMsg = '';
    if (this.funeralCoverForm.invalid) {
      this.funeralCoverForm.markAllAsTouched();
      this.validationErrorMsg = 'Beneficiary information incomplete'; 

      return;
    }
    else{
      this.validationErrorMsg = ''
    }

    this.insuranceService.saveFuneralCover(this.funeralCoverForm.value).subscribe((data: any) => {
      sessionStorage.removeItem('loadPolicyHolderDetails');
      this.successMsg = 'Funeral cover accepted successfully.';
      $('.commonhold').show();
      this.funeralParam = false;
      this.isFuneralCoverAccepted = true;
      
    }, (err: HttpErrorResponse) => {
      if(err.status === 409){
        this.errorMsg = "Funeral cover already accepted!"
      }else {
        this.errorMsg = err.error;
      }  
    });
    this.scrollToTop();

  }

  checkBoxClick(event: any) {
    console.log(event.target.checked);
  }

  calculateAge(birthDate : any) {
    return moment().diff(birthDate, 'years');
  }

  disclosures() {
    this.readDisclosure = true;
    if(this.readTerms == true)
    {
      this.creditLifeCoverForm.patchValue({ readTerms: true})
    }
    this.getInsurancePolicyDetails(1, () => {
      this.updatePolicyDetailValues(1);
    });
    this.modalService.open(this.readDisclosures,{ size: 'xl', backdrop: 'static' } );
  }
  listenDisclosures() {
    this.readDisclosure = true;
    if(this.readTerms == true)
    {
      this.creditLifeCoverForm.patchValue({ readTerms: true})
    }
  }

  lifedisclosures() {
    this.readDisclosure = true;
    if(this.readTerms == true)
    {
      this.lifeCoverForm.patchValue({ readTerms: true})
    }
    this.getInsurancePolicyDetails(2, () => {
      this.updatePolicyDetailValues(2);
    });
    this.modalService.open(this.lifeDisclosures,{ size: 'xl', backdrop: 'static' } );
  }
  listenLifeDisclosures() {
    this.readDisclosure = true;
    if(this.readTerms == true)
    {
      this.lifeCoverForm.patchValue({ readTerms: true})
    }
  }

  legaldisclosures(){
    this.readDisclosure = true;
    if(this.readTerms == true)
    {
      this.legalCoverForm.patchValue({ readTerms: true})
    }
    this.getInsurancePolicyDetails(4, () => {
      this.updatePolicyDetailValues(4);
    });
    this.modalService.open(this.legalInsurence,{ size: 'xl', backdrop: 'static' } );
  }

  listenLegalDisclosures() {
    this.readDisclosure = true;
    if(this.readTerms == true)
    {
      this.legalCoverForm.patchValue({ readTerms: true})
    }
  }

  funeraldisclosures() {
    this.readDisclosure = true;
    if(this.readTerms == true)
    {
      this.funeralCoverForm.patchValue({ readTerms: true})
    }
    this.getInsurancePolicyDetails(3, () => {
      this.updatePolicyDetailValues(3);
    });
    this.modalService.open(this.funeralDisclosures,{ size: 'xl', backdrop: 'static' } );
  }
  listenFuneralDisclosures() {
    this.readDisclosure = true;
    if(this.readTerms == true)
    {
      this.funeralCoverForm.patchValue({ readTerms: true})
    }
  }

  terms() {
    this.readTerms = true;
    if(this.readDisclosure == true)
    {
      this.creditLifeCoverForm.patchValue({ readTerms: true})
      this.lifeCoverForm.patchValue({ readTerms: true})
      this.funeralCoverForm.patchValue({ readTerms: true})
    }
    this.getInsurancePolicyDetails(1, null);
    this.modalService.open(this.termPop,{ size: 'xl', backdrop: 'static' } );
  }
  insure(){
    this.modalService.open(this.mainInsuredDetail,{ size: 'lg', backdrop: 'static' } );
  }


  callbackPopup(campaignSource) {
    this.callbackErrorMsg = '';
    this.campaignSourceToModel = campaignSource;
    this.modalService.open(this.callbackConfirmationModel, this.modalOptions);
  }
  callback(campaignSourceName) {
    console.log(campaignSourceName);
    this.callbackErrorMsg = '';
    this.callbackSuccessMsg = '';
    const param = { campaignSource: campaignSourceName };
    this.communicationService.leadCallback(param).subscribe((data: any) => {
      this.callbackErrorMsg = 'Our Financial Assessor will get in touch with you';
      $(".txthd").hide();
      $(".modal-footer").hide();
      //this.modalService.dismissAll()
    }, (err: HttpErrorResponse) => {
      this.callbackErrorMsg = 'Something went wrong. Please try again later'
    });
  }
  getDateOfBirthFromSAID(idNumber: string): Date | null {
    // Check if the ID number is valid (length = 13)
    if (idNumber.length !== 13 || isNaN(Number(idNumber))) {
        return null;
    }

    const year = parseInt(idNumber.substr(0, 2));
    const month = parseInt(idNumber.substr(2, 2));
    const day = parseInt(idNumber.substr(4, 2));

    const currentYear = new Date().getFullYear();
    const prefix = year > currentYear % 100 ? 19 : 20; // Determine the prefix for the birth year

    const birthYear = prefix * 100 + year;
    const birthDate = new Date(birthYear, month - 1, day); // Months are zero-based in JavaScript Date

    return birthDate;
}
}