
<section class="banner b5containeradjust">
    <div class="container">
        <h1 class="page-heading pt-md-5 px-3">Accept your cover</h1>
    </div>

</section>


<section class="insurenceholder pt-4 pb-4 commonhold b5containeradjust insuremcealinks">
    <div class="container ">
        <div class="w-100 px-md-3">
            <div *ngIf="successMsg!=''" class="alert alert-success " role="alert">
                {{successMsg}}
            </div>
        </div>
        <div class="w-100 px-md-3">
            <div *ngIf="errorMsg!=''" class="alert alert-danger " role="alert">
                {{errorMsg}}
            </div>
        </div>
        <!-- <h1 class="page-caption">Accept your cover</h1> -->
        <div class="px-md-3">
            <p class="ptxt">
                This is a summary of the most important information about and quote for the DebtBusters Policies presented to you. 
            </p>
            <p class="ptxt">
                The DebtBusters Policies offered to you telephonically (&#34;Policies&#34; ) are brought to you by the Intelligent Debt Management Group (&#34;IDM Group&#34;), in association with 
                <span *ngIf="insurer == 'Centriq'">Centriq Insurance Company Ltd (&#34;Centriq&#34;, &#34;we&#34;, &#34;us&#34; or &#34;our&#34;)</span>
                <span *ngIf="insurer == 'Hollard' || insurer == null || insurer == ''">Hollard Life Assurance Company Ltd and The Hollard Insurance Company Ltd (“Hollard”)</span>
                , a licensed insurer. 
            </p>
            <p class="ptxt">
                The Policies are designed specifically for debt review clients of DebtBusters (Pty) Ltd, the debt counselling arm of the IDM Group responsible for your debt review.
            </p>
        </div>

        

        <div class="d-flex px-md-3">
            <div class="col-md-12 px-0">
                <div class="d-flex mb-md-4 mb-2 bg-lggry justify-content-between flex-wrap">
                    <div class="col-md-12 text-lbl px-md-0 px-3 mt-3 col-12">
                        <strong>Policyholder and main insured details:</strong> 
                        <span class="text-sm">(applicable to all Policies)</span>
                    </div>
                    <div class="col-md-12 col-12 d-flex justify-content-between text-lbl  px-3 px-md-0 mt-3">
                        <div class="col-7 px-0"><span class="txt-md">{{firstName}} {{lastName}}</span></div>
                        <div class="col-5 pe-0 text-end">
                            <a id="anchor-View2-Insurance" class="viewLink" (click)="insure()" href="javascript:void(0)">View</a>
                        </div>
                    </div>
                    
                </div>
                <div *ngIf="spouseFirstName != null" >
                    <div *ngIf="spouseFirstName != ''" class="d-flex flex-wrap mb-md-4 mb-2 bg-lggry justify-content-between">
                   
                    <div class="col-md-12  px-3 px-md-0 text-lbl">
                        <strong>Second life insured details: </strong>
                        <span class="text-sm">(only applicable to Credit Life Policy)</span>
                    </div>
                    <div class="col-md-12 col-12 d-flex justify-content-between text-lbl  px-3 px-md-0 mt-3">
                        <div class="col-7 px-0"><span class="txt-md">{{spouseFirstName}} {{spouseLastName}}</span> </div>
                        <div class="col-5  pe-0 text-end">
                            <a id="anchor-View-Insurance" class="viewLink" (click)="insure()" href="javascript:void(0)">View</a>
                        </div>
                    </div>
                    </div>
                </div>
                <div class="d-flex flex-wrap mb-md-4 mb-2 bg-lggry justify-content-between">
                    <div class="col-md-12 col-12 px-3 px-md-0 text-lbl">
                        <strong>Your insurer is:</strong>
                    </div>
                    <div class="col-md-12 col-12 justify-content-between text-lbl  px-3 px-md-0 d-flex mt-3">
                        <div class="col-7 px-0">
                            <span  *ngIf="insurer == 'Centriq'" class="txt-md">Centriq</span>
                            <span   *ngIf="insurer == 'Hollard' || insurer == null || insurer == ''" class="txt-md">Hollard</span>
                        </div>
                        <div class="col-5  pe-0 text-end" >
                            <a  class="viewLink" id="anchor-View1-Insurance" (click)="insure()" href="javascript:void(0)">View</a>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
        <div class="px-md-3">
            <div class="d-flex flex-wrap row">
                <div class="col-md-12">
                    <h1 class="page-caption mb-3 mt-4 mb-4 ">Steps to accept your insurance:</h1>
                    <div class="step-holder d-flex row">
                        <div class="col-md-4 counter position-relative">
                            <span class="span-count">1</span>
                            <p class="steptext px-md-5">
                                Read or listen to the policy-specific disclosures.
                            </p>
                        </div>
                        <div class="col-md-4 counter position-relative">
                            <span class="span-count"> 2</span>
                            <p class="steptext px-md-5">
                                Confirm that the policy details are reflected correctly.
                            </p>
                            <p class="text-center pb-3 d-none d-md-block">
                                <span class="textlg pt-4 ">Contact us for assistance</span>
                                <span class="text-dark">Email: </span> <a id="anchor-Mail-Insurance" href="mailto:enquiries@jmprotect.co.za">enquiries@jmprotect.co.za</a>
                                <br/>
                                <span class="text-dark">Telephone: </span> <a id="anchor-CellNumber-Insurance" href="tel:0860994096">086 099 4096</a>
                            </p>
                        </div>
                        <div class="col-md-4 counter position-relative">
                            <span class="span-count"> 3</span>
                            <p class="steptext px-md-5">
                                Confirm you understand the policy Disclosures, Terms & Conditions and Accept the Policy.
                            </p>
    
                            
                        </div>
    
                        
                    </div>
                    <p class="text-center pb-3 d-block d-md-none alinkborder">
                        <span class="textlg ">Contact us for assistance</span>
                        <span class="text-dark">Email: </span> <a id="anchor-Mail1-Insurance" href="mailto:enquiries@jmprotect.co.za">enquiries@jmprotect.co.za</a>
                        <br/>
                        <span class="text-dark">Telephone: </span> <a id="anchor-CellNumber1-Insurance" href="tel:0860994096">086 099 4096</a>
                    </p>
                </div>
                <!-- <div class="col-md-2 px-md-0 px-3  d-flex align-items-end">
                   
                    <div class="d-flex w-100 align-items-center">
                        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M26.6842 17.0834H29C29 11.3352 24.5155 7 18.5675 7V9.241C23.2708 9.241 26.6842 12.5386 26.6842 17.0834Z" fill="#64A346"/>
                            <path d="M18.5791 13.723C21.0141 13.723 22.0527 14.7281 22.0527 17.0845H24.3685C24.3685 13.4709 22.3133 11.482 18.5791 11.482V13.723ZM22.5414 19.8219C22.3189 19.6262 22.0265 19.5218 21.726 19.5308C21.4255 19.5398 21.1403 19.6615 20.9307 19.8701L18.1599 22.6276C17.493 22.5044 16.1522 22.0999 14.772 20.7676C13.3918 19.4308 12.9738 18.1299 12.8499 17.489L15.6971 14.8065C15.913 14.6039 16.0389 14.3279 16.0482 14.037C16.0575 13.7461 15.9494 13.4631 15.7469 13.2479L11.4686 8.69532C11.266 8.47946 10.9844 8.34853 10.6837 8.33033C10.383 8.31212 10.0868 8.40809 9.85797 8.59783L7.34537 10.6831C7.14519 10.8775 7.02571 11.1363 7.00959 11.4103C6.99222 11.6904 6.66107 18.326 11.978 23.4736C16.6165 27.9612 22.4267 28.2895 24.0269 28.2895C24.2608 28.2895 24.4044 28.2828 24.4426 28.2806C24.7257 28.2652 24.993 28.1491 25.1929 27.9545L27.3465 25.5219C27.5428 25.3006 27.6421 25.014 27.6235 24.723C27.6049 24.432 27.4699 24.1595 27.247 23.9633L22.5414 19.8219Z" fill="#64A346"/>
                            <circle cx="18" cy="18" r="17.5" stroke="#64A346"/>
                        </svg>
                        <div class="helptext">
                            <span class="ndhelp d-block">Need Help?</span>
                            <span class="calltext d-block"> Call us on <a class="catlink" href="tel:0872380652">087 2380 652</a></span>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>



        <!-- <h1 class="page-caption mb-3 mt-4">Click on each product to view</h1> -->
        <div class="px-md-3">
            <div class="d-flex flex-wrap row pb-4 align-items-stretch">
            
                <div class="col-md-3 linklist d-flex align-items-stretch" *ngIf="hasCreditLifeCover">
                    <!---->
                    <div id="CreditLifecoverSubmit"  
                        class="coverlink w-100 {{(isCreditLifeCoverAccepted || state == 'Accepted') ? 'active' : ''}}"
                        name="InsuranceCreditLifeCover">
                        <!--desktop-->
                        <div class="d-none d-md-flex align-items-stretch flex-wrap">
                            <i class="notaccpt"></i>
                            <h4 class="linkcaption text-center w-100">Credit Life Cover</h4>
                            <i class="insurnc-icon-creditLifeCover"></i>
                            <div class="text-center mx-auto">
                                <a (click)="creditLifeForm()" id="anchor-CreditLifeForm-Insurance" class="viwlink" href="javascript:void(0)"> {{(isCreditLifeCoverAccepted || state == 'Accepted') ? 'View' : 'Update'}}</a>
                            </div>
                        </div>
                        <!--mobile-->
                        <div class="d-flex align-items-center d-md-none">
                            <i class="notaccpt"></i>
                            <i class="insurnc-icon-creditLifeCover"></i>
                            <h4 class="linkcaption">Credit Life Cover</h4>
                            <div class="text-center ms-auto">
                                <a (click)="creditLifeForm()" id="anchor-CreditLifeForm1-Insurance" class="viwlink" href="javascript:void(0)">  {{(isCreditLifeCoverAccepted || state == 'Accepted') ? 'View' : 'Update'}}</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 linklist d-flex align-items-stretch" *ngIf="hasLifeCover">
                    <!---->
                    <div href="javascript:void(0)"  id="lifecoverSubmit"  
                        class="coverlink w-100 {{(isLifeCoverAccepted || state == 'Accepted') ? 'active' : ''}}"
                        name="InsuranceLifeCover">
                        <!--desktop-->
                        <div class="d-none d-md-flex align-items-stretch flex-wrap">
                            <i class="notaccpt"></i>
                            <h4 class="linkcaption text-center w-100">Life Cover</h4>
                            <i class="insurnc-icon-lifeCover"></i>
                            <div class="text-center  mx-auto mt-auto">
                                <a (click)="lifeForm()" id="anchor-LifeForm-Insurance" class="viwlink" href="javascript:void(0)"> {{(isLifeCoverAccepted || state == 'Accepted') ? 'View' : 'Update'}}</a>
                            </div>
                        </div>
                        <!--mobile-->
                        <div class="d-flex  align-items-center d-md-none">
                            <i class="notaccpt"></i>
                            <i class="insurnc-icon-lifeCover"></i>
                            <h4 class="linkcaption">Life Cover</h4>
                            
                            <div class="text-center  ms-auto">
                                <a (click)="lifeForm()" id="anchor-LifeForm1-Insurance" class="viwlink" href="javascript:void(0)"> {{(isLifeCoverAccepted || state == 'Accepted') ? 'View' : 'Update'}}</a>
                            </div>
                        </div>
    
                        
                    </div>
                </div>
    
                 <!--Legal Protection-->
                 <div class="col-md-3 linklist d-flex align-items-stretch" *ngIf="hasLegalCover">
                    <!---->
                    <div href="javascript:void(0)"  id="legalcoverSubmit"  
                        class="coverlink w-100 {{(isLegalCoverAccepted || state == 'Accepted') ? 'active' : ''}}"
                        name="InsuranceLegalCover">
                        <!--desktop-->
                        <div class="d-none d-md-flex align-items-stretch flex-wrap">
                            <i class="notaccpt"></i>
                            <h4 class="linkcaption text-center w-100">Legal Insurance</h4>
                            <i class="insurnc-icon-legal-protection"></i>
                            <div class="text-center  mx-auto">
                                <a (click)="legalForm()" id="anchor-LegalForm-Insurance" class="viwlink" href="javascript:void(0)"> {{(isLegalCoverAccepted || state == 'Accepted') ? 'View' : 'Update'}}</a>
                            </div>
                        </div>
                        <!--mobile-->
                        <div class="d-flex  align-items-center d-md-none">
                            <i class="notaccpt"></i>
                            <i class="insurnc-icon-legal-protection"></i>
                            <h4 class="linkcaption">Legal Insurance</h4>
                            
                            <div class="text-center  ms-auto">
                                <a (click)="legalForm()" id="anchor-LegalForm-Insurance" class="viwlink" href="javascript:void(0)"> {{(isLegalCoverAccepted || state == 'Accepted') ? 'View' : 'Update'}}</a>
                            </div>
                        </div>
    
                        
                    </div>
                </div>
                <!--Legal Protection-->
    
                <div class="col-md-3 linklist d-flex align-items-stretch" *ngIf="hasFuneralCover">
                    <!---->
                    <div href="javascript:void(0)" id="funeralCcoverSubmit" 
                        class="coverlink w-100 {{(isFuneralCoverAccepted || state == 'Accepted') ? 'active' : ''}}"
                        name="InsuranceFuneralCover">
                        <!--desktop-->
                        <div class="d-none d-md-flex align-items-stretch flex-wrap">
                            <i class="notaccpt"></i>
                            <h4 class="linkcaption text-center w-100">Funeral Cover</h4>
                            <i class="insurnc-icon-funeralCover"></i>
                            <div class="text-center mx-auto">
                                <a (click)="funeralForm()" id="anchor-FuneralForm-Insurance" class="viwlink " href="javascript:void(0)"> {{(isFuneralCoverAccepted || state == 'Accepted') ? 'View' : 'Update'}}</a>
                            </div>
                        </div>
                        <!--mobile-->
                        <div class="d-flex  align-items-center d-md-none">
                            <i class="notaccpt"></i>
                            <i class="insurnc-icon-funeralCover"></i>
                            <h4 class="linkcaption">Funeral Cover</h4>
                            <div class="text-center ms-auto">
                                <a (click)="funeralForm()" id="anchor-FuneralForm-Insurance" class="viwlink" href="javascript:void(0)"> {{(isFuneralCoverAccepted || state == 'Accepted') ? 'View' : 'Update'}}</a>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex flex-wrap pb-4 row text-center" *ngIf="(acceptedDate != '' && acceptedDate != null) || 
                                                     (acceptanceMethod != '' && acceptanceMethod != null)">
            <div class="col-md-2"></div>
            <div class="pt-4 col-md-8"> <p> Agreement accepted <span *ngIf="acceptedDate != '' && acceptedDate != null">on {{acceptedDate |  date:'MM/dd/yyyy'}}</span> <span *ngIf="acceptanceMethod != '' && acceptanceMethod != null"> via {{acceptanceMethod}}</span>.</p></div>
            <div class="col-md-2"></div>
        </div>
        
    </div>
</section>

<!--Credit Life Cover-->
<section class=" pt-4 pb-4 b5containeradjust insuremcealinks" *ngIf="creditLifeParam">
    <div class="container">
        <nav _ngcontent-nsr-c5="" aria-label="breadcrumb">
            <ol _ngcontent-nsr-c5="" class="breadcrumb">
                <li _ngcontent-nsr-c5="" class="breadcrumb-item"><a _ngcontent-nsr-c5="" id="anchor-RedirectToInsurance-Insurance" href="/portal/insurance">My Insurance</a></li>
                <li _ngcontent-nsr-c5="" aria-current="page" class="breadcrumb-item active">Credit Life Cover</li>
            </ol>
        </nav>
        <h1 class="page-caption text-center pt-3 pb-2">Credit Life Cover</h1>
        <form [formGroup]="creditLifeCoverForm" (ngSubmit)="submitCreditLifeCover()">
            <div class="w-100">
                <div *ngIf="successMsg!=''" class="alert alert-success " role="alert">
                    {{successMsg}}
                </div>
            </div>
            <div class="w-100">
                <div *ngIf="errorMsg!=''" class="alert alert-danger " role="alert">
                    {{errorMsg}}
                </div>
            </div>
            <div class="step-design d-flex justify-content-center">
                <div class="col-lg-8 col-12">
                    <div class="step-block mb-4">
                        <h2 class="page-caption mb-3">Step 1: Disclosures</h2>
                        <div class="bg-gry">
                            <div class="form-group row">
                                <label class="col-sm-12 text-center col-form-label text-lg-green">Listen to the audio Disclosures
                                </label>
                                <div class="col-sm-12 d-flex justify-content-center">
                                    <audio controls class="audio" (play)="listenLifeDisclosures()" name="CreditLifeDisclosurePlay">
                                        <source src="../../assets/audio/credit-life.wav" type="audio/mpeg">
                                    </audio>
                                </div>
                            </div>
                            <div class="form-group row justify-content-center">
                                
                                <div class="col-sm-7 d-flex justify-content-center">
                                    <span class="ordivider">Or</span>
                                </div>
                            </div>
                            <div class="form-group row justify-content-center">
                                <div class="col-sm-7 d-flex justify-content-center">
                                    <a href="javascript:void(0)" id="anchor-ReadDisclosures-Insurance" (click)="disclosures()" class="readDisclosures" name="CreditLifeDisclosure">Read disclosures</a>
                                </div>
                            </div>
                        </div>
                        
                        <!-- <audio id="player" src="../../assets/images/sample.mp3"></audio>
                        <div> 
                            <button onclick="document.getElementById('player').play()">Play</button> 
                            <button onclick="document.getElementById('player').pause()">Pause</button> 
                            <button onclick="document.getElementById('player').volume += 0.1">Vol +</button> 
                            <button onclick="document.getElementById('player').volume -= 0.1">Vol -</button> 
                        </div>-->
                    </div>
                    <div class="step-block mb-4">
                        <h2 class="page-caption mb-3">Step 2: Policy Details</h2>
                        <div class="bg-gry">
                                <div class="form-group row align-items-center">
                                    <label class="col-sm-5 col-form-label">Premium for death, disability and critical illness	
                                    </label>
                                    <div class="col-sm-7">
                                        R {{creditLifeCoverFormControls.creditLifePremium.value}}
                                    </div>
                                </div>
                                <div class="form-group row align-items-center">
                                    <label class="col-sm-5 col-form-label">Premium for loss of income	
                                    </label>
                                    <div class="col-sm-7">
                                        R {{creditLifeCoverFormControls.retrenchmentPremium.value}}
                                    </div>
                                </div>
                                <div class="form-group row align-items-center">
                                    <label class="col-sm-5 col-form-label ">Total premium		
                                    </label>
                                    <div class="col-sm-7">
                                        R {{creditLifeCoverFormControls.totalPremium.value}}
                                    </div>
                                </div>
                                <div class="form-group row align-items-center">
                                    <label class="col-sm-5 col-form-label ">Sum Insured
                    
                                    </label>
                                    <div class="col-sm-7">
                                        R {{creditLifeCoverFormControls.coverAmount.value}}
                                    </div>
                                </div>
                                <div class="form-group row align-items-center">
                                    <label class="col-sm-5 col-form-label ">Monthly Insured Sum
                    
                                    </label>
                                    <div class="col-sm-7">
                                        R {{creditLifeCoverFormControls.rehabCovered.value}}
                                    </div>
                                </div>
                                <div class="form-group row align-items-center">
                                    <label class="col-sm-5 col-form-label ">Beneficiary Name
                    
                                    </label>
                                    <div class="col-sm-7 align-items-center">
                                        <input type="text" class="form-control" formControlName="beneficiaryFirstName" id="beneficiaryFirstName" [readOnly]="creditLifeCoverFormControls.acceptTerms.value">
                                        <div *ngIf="creditLifeCoverFormControls.beneficiaryFirstName.touched && creditLifeCoverFormControls.beneficiaryFirstName.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="creditLifeCoverFormControls.beneficiaryFirstName.errors.required">
                                                Beneficiary name is required
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--Last Name-->
                                <div class="form-group row align-items-center">
                                    <label class="col-sm-5 col-form-label">Beneficiary Last Name
                    
                                    </label>
                                    <div class="col-sm-7 align-items-center">
                                        <input type="text" class="form-control" formControlName="beneficiaryLastName" id="beneficiaryLastName" [readOnly]="creditLifeCoverFormControls.acceptTerms.value">
                                        <div *ngIf="creditLifeCoverFormControls.beneficiaryLastName.touched && creditLifeCoverFormControls.beneficiaryLastName.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="creditLifeCoverFormControls.beneficiaryLastName.errors.required">
                                                Beneficiary Last name is required
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                 <!--Last Name-->
                                <div class="form-group row align-items-center">
                                    <label class="col-sm-5 col-form-label">Beneficiary Relationship
                                    </label>
                                    <div class="col-sm-7">
                                        <input type="text" class="form-control" formControlName="beneficiaryRelationship" id="beneficiaryRelationship" [readOnly]="creditLifeCoverFormControls.acceptTerms.value">
                                        <div *ngIf="creditLifeCoverFormControls.beneficiaryRelationship.touched && creditLifeCoverFormControls.beneficiaryRelationship.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="creditLifeCoverFormControls.beneficiaryRelationship.errors.required">
                                                Beneficiary relationship is required
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row align-items-center">
                                    <label class="col-sm-5 col-form-label">Beneficiary Date of Birth
                                    </label>
                                    <div class="col-sm-7">
                                        <!-- <input type="date" class="form-control" formControlName="beneficiaryDateOfBirth" max="{{dobUpto | date:'yyyy-MM-dd'}}" id="beneficiaryDateOfBirth" 
                                            [readOnly]="creditLifeCoverFormControls.acceptTerms.value" > -->
                                        <div class="position-relative">
                                            <input  formControlName="beneficiaryDateOfBirth"
                                                    id="beneficiaryDateOfBirth"
                                                    class="form-control"
                                                    placeholder="mm/dd/yyyy"                                   
                                                    [(ngModel)]="creditLifeCoverForm.value.beneficiaryDateOfBirth"
                                                    ngbDatepicker
                                                    #calendar="ngbDatepicker"
                                                    (click)="calendar.toggle()"
                                                    (ngModelChange)="change('mainBeneficiaryDateOfBirth')"
                                                    max="{{dobUpto | date:'yyyy-MM-dd'}}"
                                                    [readOnly]="creditLifeCoverFormControls.acceptTerms.value"
                                                    [disabled]="creditLifeCoverFormControls.acceptTerms.value"
                                                    value="{{creditLifeCoverForm.value.beneficiaryDateOfBirth | date:'MM/dd/yyyy'}}" />
                                                   
                                            <button class="btn btn-outline-secondary bi bi-calendar3" (click)="calendar.toggle()" id="btn-CalenderToggle-Insurance" type="button" [disabled]="creditLifeCoverFormControls.acceptTerms.value"></button>
                                        </div>                                       
                                        <div *ngIf="creditLifeCoverFormControls.beneficiaryDateOfBirth.touched && creditLifeCoverFormControls.beneficiaryDateOfBirth.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="creditLifeCoverFormControls.beneficiaryDateOfBirth.errors.required">
                                                Beneficiary date of birth is required
                                            </div>
                                            <div *ngIf="creditLifeCoverFormControls.beneficiaryDateOfBirth.errors?.underAge">
                                                Beneficiary needs to be 18 years and older, Please amend to proceed.
                                              </div>
                                        </div>
                                    </div>
                                </div>
                            <div class="accountdetail">
                                <h3 class="subcaption">Accounts covered in Policy</h3>
                                <div class="form-group row align-items-center" *ngFor="let coveredAccounts of creditLifeCoverFormControls.coveredAccounts.value">
                                    <label class="col-sm-5 col-5 col-form-label text-md-end">
                                        {{coveredAccounts.creditor}}	
                                    </label>
                                    <div class="col-sm-7 col-7">
                                        {{coveredAccounts.accountNumber}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div class="pb-4 texthighlight">Please note that the above numbers may fluctuate slightly after the debt review negotiations with your credit providers are finalised.</div>
                    <div class="step-block">
                        <div class="d-flex justify-content-between flex-wrap align-items-center mb-2">
                            <h2 class="page-caption mb-0">Step 3: Accept the Policy</h2>
                            <div class="d-flex pt-md-0 pt-3 align-items-center">
                                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M26.6842 17.0834H29C29 11.3352 24.5155 7 18.5675 7V9.241C23.2708 9.241 26.6842 12.5386 26.6842 17.0834Z" fill="#64A346"/>
                                    <path d="M18.5791 13.723C21.0141 13.723 22.0527 14.7281 22.0527 17.0845H24.3685C24.3685 13.4709 22.3133 11.482 18.5791 11.482V13.723ZM22.5414 19.8219C22.3189 19.6262 22.0265 19.5218 21.726 19.5308C21.4255 19.5398 21.1403 19.6615 20.9307 19.8701L18.1599 22.6276C17.493 22.5044 16.1522 22.0999 14.772 20.7676C13.3918 19.4308 12.9738 18.1299 12.8499 17.489L15.6971 14.8065C15.913 14.6039 16.0389 14.3279 16.0482 14.037C16.0575 13.7461 15.9494 13.4631 15.7469 13.2479L11.4686 8.69532C11.266 8.47946 10.9844 8.34853 10.6837 8.33033C10.383 8.31212 10.0868 8.40809 9.85797 8.59783L7.34537 10.6831C7.14519 10.8775 7.02571 11.1363 7.00959 11.4103C6.99222 11.6904 6.66107 18.326 11.978 23.4736C16.6165 27.9612 22.4267 28.2895 24.0269 28.2895C24.2608 28.2895 24.4044 28.2828 24.4426 28.2806C24.7257 28.2652 24.993 28.1491 25.1929 27.9545L27.3465 25.5219C27.5428 25.3006 27.6421 25.014 27.6235 24.723C27.6049 24.432 27.4699 24.1595 27.247 23.9633L22.5414 19.8219Z" fill="#64A346"/>
                                    <circle cx="18" cy="18" r="17.5" stroke="#64A346"/>
                                </svg>
                                <div class="helptext">
                                    <span class="ndhelp d-block">Need Help?</span>
                                    <span class="calltext d-block"> Call us on <a id="anchor-CellNumber2-Insurance" class="catlink" href="tel:0872380652">087 2380 652</a></span>
                                </div>
                            </div>
                        </div>
                        
                        <div class="bg-gry">
                                <div class="form-group d-flex flex-wrap mb-0 align-items-center">
                                    <input type="checkbox" id="input-ReadTerms-Insurance" formControlName="readTerms" [checked]="creditLifeCoverFormControls.readTerms.value" 
                                            [attr.disabled]="(isCreditLifeCoverAccepted || state == 'Accepted') ? 'true' : null">
                                    <label class="col-10 col-form-label ps-3">
                                        I have read or listened to and understand the <a href="javascript:void(0)" id="anchor-CreditLifeDisclosures-Insurance" (click)="disclosures()" name="CreditLifeDisclosure">disclosures</a> and <a id="anchor-Terms-Insurance" href="javascript:void(0)" (click)="terms()" name="T&C">T&#39;s&C&#39;s</a>	
                                    </label>
                                    <div class="col-sm-12">
                                        
                                        <div *ngIf="creditLifeCoverFormControls.readTerms.touched && creditLifeCoverFormControls.readTerms.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="creditLifeCoverFormControls.readTerms.errors.pattern">
                                                Please read or listen to disclosures and T&#39;s&C&#39;s
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group mb-0 d-flex flex-wrap align-items-center">
                                    <input type="checkbox" id="input-Acceptterms-Insurance" formControlName="acceptTerms" (change)="checkBoxClick($event)" 
                                            [attr.disabled]="(isCreditLifeCoverAccepted || state == 'Accepted') ? 'true' : null"/>
                                        
                                    <label class="col-10 col-form-label ps-3">
                                        I Accept
                                    </label>
                                    <div class="col-12">
                                        <div *ngIf="creditLifeCoverFormControls.acceptTerms.touched && creditLifeCoverFormControls.acceptTerms.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="creditLifeCoverFormControls.acceptTerms.errors.pattern">
                                                Acceptance is required
                                            </div>
                                        </div>
                                        <div class="invalid-feedback" *ngIf="creditLifeCoverForm.invalid  && (creditLifeCoverForm.dirty || creditLifeCoverForm.touched)">
                                            <div *ngIf="!creditLifeCoverFormControls.acceptTerms.errors">
                                                {{ validationErrorMsg }}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                
                        </div>
                    </div>
                    <div class="step-block mt-3" *ngIf="!isCreditLifeCoverAccepted && state == 'Pending'">
                        <div class="form-group row align-items-center">
                            <label class="col-sm-5 col-form-label">
                                
                            </label>
                            <div class="col-sm-7 text-md-start text-center">
                                <button type="submit" name="SubmitCreditLifeCover" id="btn-SubmitCreditLifeCover-Insurance" class="btn-custom" >Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</section>
<!--Life Cover-->
<section class=" pt-4 pb-4 b5containeradjust insuremcealinks" *ngIf="lifeParam">
    <div class="container">
        <nav _ngcontent-nsr-c5="" aria-label="breadcrumb">
            <ol _ngcontent-nsr-c5="" class="breadcrumb">
                <li _ngcontent-nsr-c5="" class="breadcrumb-item"><a _ngcontent-nsr-c5="" id="anchor-RedirectToInsurance1-Insurance" href="/portal/insurance">My Insurance</a></li>
                <li _ngcontent-nsr-c5="" aria-current="page" class="breadcrumb-item active">Life Cover</li>
            </ol>
        </nav>
        <h1 class="page-caption text-center pt-3 pb-2"> Life Cover</h1>
        <form [formGroup]="lifeCoverForm" (ngSubmit)="submitLifeCover()">
            <div class="w-100">
                <div *ngIf="successMsg!=''" class="alert alert-success " role="alert">
                    {{successMsg}}
                </div>
            </div>
            <div class="w-100">
                <div *ngIf="errorMsg!=''" class="alert alert-danger " role="alert">
                    {{errorMsg}}
                </div>
            </div>
            <div class="step-design d-flex justify-content-center">
                <div class="col-lg-8 col-12">
                    <div class="step-block mb-4">
                        <h2 class="page-caption mb-3">Step 1: Disclosures</h2>
                        <div class="bg-gry">
                            <div class="form-group row">
                                <label class="col-sm-12 text-center col-form-label text-lg-green">Listen to the audio Disclosures
                                </label>
                                <div class="col-sm-12 d-flex justify-content-center">
                                    <audio controls class="audio" (play)="listenLifeDisclosures()" name="LifeDisclosurePlay">
                                        <source src="../../assets/audio/life-cover.wav" type="audio/mpeg">
                                    </audio>
                                </div>
                            </div>
                            <div class="form-group row justify-content-center">
                                
                                <div class="col-sm-7 d-flex justify-content-center">
                                    <span class="ordivider">Or</span>
                                </div>
                            </div>
                            <div class="form-group row justify-content-center">
                                <div class="col-sm-7 d-flex justify-content-center">
                                    <a href="javascript:void(0)" id="anchor-LifeDisclosures-Insurance" (click)="lifedisclosures()" class="readDisclosures" name="LifeDisclosure">Read disclosures</a>
                                </div>
                            </div>
                        </div>
                        
                        <!-- <audio id="player" src="../../assets/images/sample.mp3"></audio>
                        <div> 
                            <button onclick="document.getElementById('player').play()">Play</button> 
                            <button onclick="document.getElementById('player').pause()">Pause</button> 
                            <button onclick="document.getElementById('player').volume += 0.1">Vol +</button> 
                            <button onclick="document.getElementById('player').volume -= 0.1">Vol -</button> 
                        </div>-->
                    </div>
                    <div class="step-block mb-4">
                        <h2 class="page-caption mb-3">Step 2: Policy Details</h2>
                        <div class="bg-gry">
                                <div class="form-group row align-items-center">
                                    <label class="col-sm-5 col-form-label">Premium per month</label>
                                    <div class="col-sm-7">
                                        R {{lifeCoverFormControls.premium.value}}
                                    </div>
                                </div>
                                <div class="form-group row align-items-center">
                                    <label class="col-sm-5 col-form-label">Sum Insured for death</label>
                                    <div class="col-sm-7">
                                        R {{lifeCoverFormControls.coverAmount.value}}
                                    </div>
                                </div>
                                <div class="form-group row align-items-center">
                                    <label class="col-sm-5 col-form-label">Sum Insured for permanent disability</label>
                                    <div class="col-sm-7">
                                        R {{lifeCoverFormControls.permanentDisabilityAmount.value}}
                                    </div>
                                </div>
                                <div class="form-group row align-items-center">
                                    <label class="col-sm-5 col-form-label">Sum Insured for critical illness</label>
                                    <div class="col-sm-7">
                                        R {{lifeCoverFormControls.dreadDiseaseAmount.value}}
                                    </div>
                                </div>
                                
                                <div class="form-group row align-items-center">
                                    <label class="col-sm-5 col-form-label">Beneficiary Name</label>
                                    <div class="col-sm-7 align-items-center">
                                        <input type="text" id="input-beneficiaryFirstName-Insurance" class="form-control" formControlName="beneficiaryFirstName" 
                                            [readOnly]="lifeCoverFormControls.acceptTerms.value">
                                        <div *ngIf="lifeCoverFormControls.beneficiaryFirstName.touched && lifeCoverFormControls.beneficiaryFirstName.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="lifeCoverFormControls.beneficiaryFirstName.errors.required">
                                                Beneficiary Name is required
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Last Name-->
                                <div class="form-group row align-items-center">
                                    <label class="col-sm-5 col-form-label">Beneficiary Last Name</label>
                                    <div class="col-sm-7 align-items-center">
                                        <input type="text" id="input-beneficiaryLastName-Insurance" class="form-control" formControlName="beneficiaryLastName" 
                                            [readOnly]="lifeCoverFormControls.acceptTerms.value">
                                        <div *ngIf="lifeCoverFormControls.beneficiaryLastName.touched && lifeCoverFormControls.beneficiaryLastName.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="lifeCoverFormControls.beneficiaryLastName.errors.required">
                                                Beneficiary Last Name is required
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Last Name-->
                                <div class="form-group row align-items-center">
                                    <label class="col-sm-5 col-form-label">Beneficiary Relationship
                                    </label>
                                    <div class="col-sm-7">
                                        <input type="text" id="input-beneficiaryRelationship-Insurance" class="form-control" formControlName="beneficiaryRelationship" 
                                            [readOnly]="lifeCoverFormControls.acceptTerms.value">
                                        <div *ngIf="lifeCoverFormControls.beneficiaryRelationship.touched && lifeCoverFormControls.beneficiaryRelationship.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="lifeCoverFormControls.beneficiaryRelationship.errors.required">
                                                Beneficiary Relationship is required
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row align-items-center">
                                    <label class="col-sm-5 col-form-label">Beneficiary Date of Birth
                                    </label>
                                    <div class="col-sm-7">
                                        <!--<input type="date" class="form-control" formControlName="beneficiaryDateOfBirth" max="{{dobUpto | date:'yyyy-MM-dd'}}"
                                        [readOnly]="lifeCoverFormControls.acceptTerms.value"> -->
                                        <div class="position-relative">
                                            <input  formControlName="beneficiaryDateOfBirth"
                                                    id="beneficiaryDateOfBirth"
                                                    class="form-control"
                                                    placeholder="mm/dd/yyyy"                                   
                                                    [(ngModel)]="lifeCoverForm.value.beneficiaryDateOfBirth"
                                                    ngbDatepicker
                                                    #calendar="ngbDatepicker"
                                                    (click)="calendar.toggle()"
                                                    max="{{dobUpto | date:'yyyy-MM-dd'}}"
                                                    [readOnly]="lifeCoverFormControls.acceptTerms.value"
                                                    [disabled]="lifeCoverFormControls.acceptTerms.value"
                                                    value="{{lifeCoverForm.value.beneficiaryDateOfBirth | date:'MM/dd/yyyy'}}" />                                            
                                            <button class="btn btn-outline-secondary bi bi-calendar3" id="btn-CalenderToggle1-Insurance" (click)="calendar.toggle()" type="button" [disabled]="lifeCoverFormControls.acceptTerms.value" ></button>
                                        </div>
                                        <div *ngIf="lifeCoverFormControls.beneficiaryDateOfBirth.touched && lifeCoverFormControls.beneficiaryDateOfBirth.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="lifeCoverFormControls.beneficiaryDateOfBirth.errors.required">
                                                Beneficiary Date of Birth is required
                                            </div>
                                            <div *ngIf="lifeCoverFormControls.beneficiaryDateOfBirth.errors?.underAge">
                                                Beneficiary needs to be 18 years and older, Please amend to proceed.
                                              </div>
                                        </div>
                                    </div>
                                </div>
                            <div class="accountdetail">
                                <h3 class="subcaption"></h3>
                                <div class="form-group row align-items-center">
                                    <label class="col-sm-5 col-5 col-form-label">
                                        Policy benefits
        
                                    </label>
                                    <div class="col-sm-7 col-7">
                                        Death	, Permanent Disability , Critical illness	
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        
                    </div>
                    <div class="step-block">
                        <div class="d-flex justify-content-between flex-wrap align-items-center mb-2">
                            <h2 class="page-caption mb-0">Step 3: Accept the Policy</h2>
                            <div class="d-flex pt-md-0 pt-3 align-items-center">
                                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M26.6842 17.0834H29C29 11.3352 24.5155 7 18.5675 7V9.241C23.2708 9.241 26.6842 12.5386 26.6842 17.0834Z" fill="#64A346"/>
                                    <path d="M18.5791 13.723C21.0141 13.723 22.0527 14.7281 22.0527 17.0845H24.3685C24.3685 13.4709 22.3133 11.482 18.5791 11.482V13.723ZM22.5414 19.8219C22.3189 19.6262 22.0265 19.5218 21.726 19.5308C21.4255 19.5398 21.1403 19.6615 20.9307 19.8701L18.1599 22.6276C17.493 22.5044 16.1522 22.0999 14.772 20.7676C13.3918 19.4308 12.9738 18.1299 12.8499 17.489L15.6971 14.8065C15.913 14.6039 16.0389 14.3279 16.0482 14.037C16.0575 13.7461 15.9494 13.4631 15.7469 13.2479L11.4686 8.69532C11.266 8.47946 10.9844 8.34853 10.6837 8.33033C10.383 8.31212 10.0868 8.40809 9.85797 8.59783L7.34537 10.6831C7.14519 10.8775 7.02571 11.1363 7.00959 11.4103C6.99222 11.6904 6.66107 18.326 11.978 23.4736C16.6165 27.9612 22.4267 28.2895 24.0269 28.2895C24.2608 28.2895 24.4044 28.2828 24.4426 28.2806C24.7257 28.2652 24.993 28.1491 25.1929 27.9545L27.3465 25.5219C27.5428 25.3006 27.6421 25.014 27.6235 24.723C27.6049 24.432 27.4699 24.1595 27.247 23.9633L22.5414 19.8219Z" fill="#64A346"/>
                                    <circle cx="18" cy="18" r="17.5" stroke="#64A346"/>
                                </svg>
                                <div class="helptext">
                                    <span class="ndhelp d-block">Need Help?</span>
                                    <span class="calltext d-block"> Call us on <a id="anchor-CellNumber3-Insurance" class="catlink" href="tel:0872380652">087 2380 652</a></span>
                                </div>
                            </div>
                        </div>
                        <div class="bg-gry">
                                <div class="form-group d-flex flex-wrap mb-0 align-items-center">
                                    <input type="checkbox" id="input-ReadTerms1-Insurance" formControlName="readTerms" [checked]="lifeCoverFormControls.readTerms.value" 
                                            [attr.disabled]="(isLifeCoverAccepted || state == 'Accepted') ? 'true' : null">
                                    <label class="col-10 col-form-label ps-3">
                                        I have read or listened to and understand the <a id="anchor-LifeDisclosures1-Insurance" href="javascript:void(0)" (click)="lifedisclosures()" name="LifeDisclosure">disclosures</a> and <a id="anchor-Terms1-Insurance" href="javascript:void(0)" (click)="terms()" name="T&C">T&#39;s&C&#39;s</a>	
                                    </label>
                                    <div class="col-12">
                                        
                                        <div *ngIf="lifeCoverFormControls.readTerms.touched && lifeCoverFormControls.readTerms.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="lifeCoverFormControls.readTerms.errors.pattern">
                                                Please read or listen to disclosures and T&#39;s&C&#39;s
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group mb-0 d-flex flex-wrap align-items-center">
                                    <input type="checkbox" id="input-Acceptterms1-Insurance" formControlName="acceptTerms" (change)="checkBoxClick($event)" 
                                            [attr.disabled]="(isLifeCoverAccepted || state == 'Accepted') ? 'true' : null"/>
                                    <label class="col-10 col-form-label ps-3">
                                        I Accept
                                    </label>
                                    <div class="col-12">
                                        <div *ngIf="lifeCoverFormControls.acceptTerms.touched && lifeCoverFormControls.acceptTerms.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="lifeCoverFormControls.acceptTerms.errors.pattern">
                                                Acceptance is required
                                            </div>
                                        </div>
                                        <div class="invalid-feedback" *ngIf="lifeCoverForm.invalid  && (lifeCoverForm.dirty || lifeCoverForm.touched)">
                                            <div *ngIf="!lifeCoverFormControls.acceptTerms.errors">
                                                {{ validationErrorMsg }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                        </div>
                    </div>
                    <div class="step-block mt-3" *ngIf="!isLifeCoverAccepted && state == 'Pending'">
                        <div class="form-group row align-items-center">
                            <label class="col-sm-5 col-form-label">
                                
                            </label>
                            <div class="col-sm-7 text-md-start text-center">
                                <button type="submit" name="SubmitLifeCover" id="btn-SubmitCreditLifeCover1-Insurance" class="btn-custom">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</section>

<!-- legal protection-->
<section class="pt-4 pb-4 b5containeradjust insuremcealinks" *ngIf="legalParam">
    <div class="container">
        <nav _ngcontent-nsr-c5="" aria-label="breadcrumb">
            <ol _ngcontent-nsr-c5="" class="breadcrumb">
                <li _ngcontent-nsr-c5="" class="breadcrumb-item"><a _ngcontent-nsr-c5="" id="anchor-RedirectToInsurance2-Insurance" href="/portal/insurance">My Insurance</a></li>
                <li _ngcontent-nsr-c5="" aria-current="page" class="breadcrumb-item active">Legal Insurance</li>
            </ol>
        </nav>
        <h1 class="page-caption text-center pt-3 pb-2"> Legal Insurance</h1>
        <form [formGroup]="legalCoverForm" (ngSubmit)="submitLegalCover()">
            <div class="w-100">
                <div *ngIf="successMsg!=''" class="alert alert-success " role="alert">
                    {{successMsg}}
                </div>
            </div>
            <div class="w-100">
                <div *ngIf="errorMsg!=''" class="alert alert-danger " role="alert">
                    {{errorMsg}}
                </div>
            </div>
            <div class="step-design d-flex justify-content-center">
                <div class="col-lg-8 col-12">
                    <div class="step-block mb-4">
                        <h2 class="page-caption mb-3">Step 1: Disclosures</h2>
                        <div class="bg-gry">
                            <div class="form-group row">
                                <label class="col-sm-12 text-center col-form-label text-lg-green">Listen to the audio Disclosures
                                </label>
                                <div class="col-sm-12 d-flex justify-content-center">
                                    <audio controls class="audio" (play)="listenLegalDisclosures()" name="LifeDisclosurePlay">
                                        <source src="../../assets/audio/legal-insurance-policy.wav" type="audio/mpeg">
                                    </audio>
                                </div>
                            </div>
                            <div class="form-group row justify-content-center">
                                
                                <div class="col-sm-7 d-flex justify-content-center">
                                    <span class="ordivider">Or</span>
                                </div>
                            </div>
                            <div class="form-group row justify-content-center">
                                <div class="col-sm-7 d-flex justify-content-center">
                                    <a href="javascript:void(0)" id="btn-LegalDisclosure-Insurance" (click)="legaldisclosures()" class="readDisclosures" name="LegalDisclosure">Read disclosures</a>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                    <div class="step-block mb-4">
                        <h2 class="page-caption mb-3">Step 2: Policy Details</h2>
                        <div class="bg-gry">
                                <div class="form-group row align-items-center">
                                    <label class="col-sm-5 col-form-label">Premium per Month</label>
                                    <div class="col-sm-7">
                                        {{ legalCoverFormControls.premium.value | currency:'R ': 'symbol' : '1.0-0' }}

                                    </div>
                                </div>
                                
                                <div class="form-group row align-items-center">
                                    <label class="col-sm-5 col-form-label">Maximum Benefit per Insured Matter</label>
                                    <div class="col-sm-7 align-items-center">
                                        {{legalCoverFormControls.maximumBenefitAmountPerInsuredMatter.value | currency:'R ': 'symbol' : '1.0-0'}} 
                                    </div>
                                </div>
                                <!-- Last Name-->
                                <div class="form-group row align-items-center">
                                    <label class="col-sm-5 col-form-label">Annual Limit</label>
                                    <div class="col-sm-7 align-items-center">
                                        {{legalCoverFormControls.annualLimit.value | currency:'R ': 'symbol' : '1.0-0'}} 
                                    </div>
                                </div>
                                <!-- Last Name-->
                                <div class="form-group row align-items-center">
                                    <label class="col-sm-5 col-form-label">Overall Benefit Limit
                                    </label>
                                    <div class="col-sm-7">
                                        {{legalCoverFormControls.overallBenefitLimit.value | currency:'R ': 'symbol' : '1.0-0'}} 
                                    </div>
                                </div>
                                <div class="accountdetail">
                                    <h3 class="subcaption">Plan Option Chosen</h3>
                                    <div class="form-group row align-items-center">
                                        <label class="col-sm-5 col-form-label">Plan Option</label>
                                        <div class="col-sm-7">
                                            <input class="form-control" id="input-LegalCover-Insurance" readonly="" type="text" value="{{legalCoverFormControls.planOption.value}}">
                                        </div>
                                    </div>
                                </div>
                                <div class="accountdetail">
                                    <h3 class="subcaption">Limitations</h3>
                                    <div class="form-group row align-items-center">
                                        <label class="col-sm-5 col-form-label">Maximum Number of Criminal Charge Claims</label>
                                        <div class="col-sm-7">
                                            3
                                        </div>
                                    </div>
                                </div>
                                
                            
                        </div>
                        
                    </div>
                    <div class="step-block">
                        <div class="d-flex justify-content-between flex-wrap align-items-center mb-2">
                            <h2 class="page-caption mb-0">Step 3: Accept the Policy</h2>
                            <div class="d-flex pt-md-0 pt-3 align-items-center">
                                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M26.6842 17.0834H29C29 11.3352 24.5155 7 18.5675 7V9.241C23.2708 9.241 26.6842 12.5386 26.6842 17.0834Z" fill="#64A346"/>
                                    <path d="M18.5791 13.723C21.0141 13.723 22.0527 14.7281 22.0527 17.0845H24.3685C24.3685 13.4709 22.3133 11.482 18.5791 11.482V13.723ZM22.5414 19.8219C22.3189 19.6262 22.0265 19.5218 21.726 19.5308C21.4255 19.5398 21.1403 19.6615 20.9307 19.8701L18.1599 22.6276C17.493 22.5044 16.1522 22.0999 14.772 20.7676C13.3918 19.4308 12.9738 18.1299 12.8499 17.489L15.6971 14.8065C15.913 14.6039 16.0389 14.3279 16.0482 14.037C16.0575 13.7461 15.9494 13.4631 15.7469 13.2479L11.4686 8.69532C11.266 8.47946 10.9844 8.34853 10.6837 8.33033C10.383 8.31212 10.0868 8.40809 9.85797 8.59783L7.34537 10.6831C7.14519 10.8775 7.02571 11.1363 7.00959 11.4103C6.99222 11.6904 6.66107 18.326 11.978 23.4736C16.6165 27.9612 22.4267 28.2895 24.0269 28.2895C24.2608 28.2895 24.4044 28.2828 24.4426 28.2806C24.7257 28.2652 24.993 28.1491 25.1929 27.9545L27.3465 25.5219C27.5428 25.3006 27.6421 25.014 27.6235 24.723C27.6049 24.432 27.4699 24.1595 27.247 23.9633L22.5414 19.8219Z" fill="#64A346"/>
                                    <circle cx="18" cy="18" r="17.5" stroke="#64A346"/>
                                </svg>
                                <div class="helptext">
                                    <span class="ndhelp d-block">Need Help?</span>
                                    <span class="calltext d-block"> Call us on <a id="anchor-CellNumber4-Insurance" class="catlink" href="tel:0872380652">087 2380 652</a></span>
                                </div>
                            </div>
                        </div>
                        <div class="bg-gry">
                                <div class="form-group d-flex flex-wrap mb-0 align-items-center">
                                    <input type="checkbox" id="input-ReadTerms2-Insurance" formControlName="readTerms" [checked]="legalCoverFormControls.readTerms.value" 
                                            [attr.disabled]="(isLegalCoverAccepted || state == 'Accepted') ? 'true' : null">
                                    <label class="col-10 col-form-label ps-3">
                                        I have read or listened to and understand the <a id="anchor-LegalDisclosure-Insurance" href="javascript:void(0)" (click)="legaldisclosures()" name="LegalDisclosure">disclosures</a> and <a href="javascript:void(0)" id="anchor-Terms2-Insurance" (click)="terms()" name="T&C">T&#39;s&C&#39;s</a>	
                                    </label>
                                    <div class="col-12">
                                        
                                        <div *ngIf="legalCoverFormControls.readTerms.touched && legalCoverFormControls.readTerms.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="legalCoverFormControls.readTerms.errors.pattern">
                                                Please read or listen to disclosures and T&#39;s&C&#39;s
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group d-flex mb-0 flex-wrap align-items-center">
                                    <input type="checkbox" id="input-Acceptterms2-Insurance" formControlName="acceptTerms" (change)="checkBoxClick($event)" 
                                            [attr.disabled]="(isLegalCoverAccepted || state == 'Accepted') ? 'true' : null"/>
                                    <label class="col-10 col-form-label ps-3">
                                        I Accept
                                    </label>
                                    <div class="col-12">
                                        <div *ngIf="legalCoverFormControls.acceptTerms.touched && legalCoverFormControls.acceptTerms.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="legalCoverFormControls.acceptTerms.errors.pattern">
                                                Acceptance is required
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                        </div>
                    </div>
                    <div class="step-block mt-3" *ngIf="!isLegalCoverAccepted && state == 'Pending'">
                        <div class="form-group row align-items-center">
                            <label class="col-sm-5 col-form-label">
                                
                            </label>
                            <div class="col-sm-7 text-md-start text-center">
                                <button type="submit" name="SubmitLegalCover" id="btn-SubmitLegalCover-Insurance" class="btn-custom">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</section>

<!--Funeral Cover-->
<section class=" pt-4 pb-4 b5containeradjust insuremcealinks" *ngIf="funeralParam">
    <div class="container">
        <nav _ngcontent-nsr-c5="" aria-label="breadcrumb">
            <ol _ngcontent-nsr-c5="" class="breadcrumb">
                <li _ngcontent-nsr-c5="" class="breadcrumb-item"><a _ngcontent-nsr-c5="" id="anchor-RedirectToInsurance3-Insurance" href="/portal/insurance">My Insurance</a></li>
                <li _ngcontent-nsr-c5="" aria-current="page" class="breadcrumb-item active">Funeral Cover</li>
            </ol>
        </nav>
        <h1 class="page-caption text-center pt-3 pb-2">Funeral Cover</h1>
        <form [formGroup]="funeralCoverForm" (ngSubmit)="submitFuneralCover()">
            <div class="w-100">
                <div *ngIf="successMsg!=''" class="alert alert-success " role="alert">
                    {{successMsg}}
                </div>
            </div>
            <div class="w-100">
                <div *ngIf="errorMsg!=''" class="alert alert-danger " role="alert">
                    {{errorMsg}}
                </div>
            </div>
            <div class="step-design d-flex justify-content-center">
                <div class="col-lg-8 col-12 px-lg-3 px-0">
                    <div class="step-block mb-4">
                        <h2 class="page-caption mb-3">Step 1: Disclosures</h2>
                        <div class="bg-gry">
                            <div class="form-group row">
                                <label class="col-sm-12 text-center col-form-label text-lg-green">Listen to the audio Disclosures
                                </label>
                                <div class="col-sm-12 d-flex justify-content-center">
                                    <audio controls class="audio" (play)="listenDisclosures()" name="FuneralDisclosurePlay">
                                        <source src="../../assets/audio/funeral-cover.wav" type="audio/mpeg">
                                    </audio>
                                </div>
                            </div>
                            <div class="form-group row justify-content-center">
                                
                                <div class="col-sm-7 d-flex justify-content-center">
                                    <span class="ordivider">Or</span>
                                </div>
                            </div>
                            <div class="form-group row justify-content-center">
                                <div class="col-sm-7 d-flex justify-content-center">
                                    <a href="javascript:void(0)" id="anchor-FuneralDisclosure-Insurance" (click)="funeraldisclosures()" class="readDisclosures" name="FuneralDisclosure">Read disclosures</a>
                                </div>
                            </div>
                        </div>
                        
                        <!-- <audio id="player" src="../../assets/images/sample.mp3"></audio>
                        <div> 
                            <button onclick="document.getElementById('player').play()">Play</button> 
                            <button onclick="document.getElementById('player').pause()">Pause</button> 
                            <button onclick="document.getElementById('player').volume += 0.1">Vol +</button> 
                            <button onclick="document.getElementById('player').volume -= 0.1">Vol -</button> 
                        </div>-->
                    </div>
                    <div class="step-block mb-4">
                        <h2 class="page-caption mb-3">Step 2: Policy Details</h2>
                        <div class="bg-gry">
                                <div class="form-group row align-items-center">
                                    <label class="col-sm-5 col-form-label">Premium per month
                                    </label>
                                    <div class="col-sm-7">
                                        R {{funeralCoverFormControls.premium.value}}
                                    </div>
                                </div>
                                
                                <div class="form-group row align-items-center">
                                    <label class="col-sm-5 col-form-label">Monthly Insured Sum
                    
                                    </label>
                                    <div class="col-sm-7">
                                        R {{funeralCoverFormControls.coverAmount.value}}
                                    </div>
                                </div>
                                
                            <div class="accountdetail">
                                <h3 class="subcaption">Member Count</h3>
                                <!-- <div class="form-group row align-items-center">
                                    <label class="col-sm-5 col-5 col-form-label">
                                        Plan option	
                                    </label>
                                    <div class="col-sm-7 col-7">
                                        <input type="text" class="form-control" formControlName="funeralScheme" id="funeralScheme"
                                            [readOnly]="true">
                                    </div>
                                </div> -->
                                <div class="form-group row align-items-center">
                                    <label class="col-sm-5 col-5 col-form-label">
                                        Maximum number of insured lives	
                                    </label>
                                    <div class="col-sm-7 col-7">
                                        <input type="text" id="input-PeopleCovered-Insurance" readonly class="form-control" formControlName="peopleCovered">
                                    </div>
                                </div>
                            </div>

                            <div class="accountdetail">
                                <h3 class="subcaption">Maximum Insured Sum</h3>
                                <div class="form-group row align-items-center">
                                    <label class="col-sm-5 col-5 col-form-label">
                                        Members under 6	
                                    </label>
                                    <div class="col-sm-7 col-7">
                                        R10 000
                                    </div>
                                </div>
                                <div class="form-group row align-items-center">
                                    <label class="col-sm-5 col-5 col-form-label">
                                        Members between 6 and 14
                                    </label>
                                    <div class="col-sm-7 col-7">

                                        R20 000

                                    </div>
                                </div>
                            </div>
                            <div class="accountdetail">
                                <h3 class="subcaption">Age limitations</h3>
                                <div class="form-group row align-items-center">
                                    <label class="col-sm-5 col-5 col-form-label">
                                        Benefit termination age	
                                    </label>
                                    <div class="col-sm-7 col-7">
                                        99
                                    </div>
                                </div>
                            </div>

                            <div class="accountdetail">
                                <h3 class="subcaption">Beneficiary details</h3>
                                <div class="">
                                    <table class="table table-sm bg-white customresponsive">
                                        <thead>
                                        <tr class="table-gry">
                                            <th scope="col">First Name</th>
                                            <th scope="col">Last Name</th>
                                            <th scope="col">Gender</th>
                                            <th scope="col">Relationship</th>
                                            <th scope="col">Date of Birth</th>
                                            <th scope="col">Age</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr class=" bgch">
                                            <th class="green" colspan="6">Beneficiary details (Main insured life)</th>
                                        </tr>
                                        <tr>
                                            <td data-label="First Name">
                                                <input type="text" class="form-control" formControlName="beneficiaryFirstName" id="beneficiaryFirstName"
                                                    [readOnly]="funeralCoverFormControls.acceptTerms.value" disabled>
                                                <div *ngIf="funeralCoverFormControls.beneficiaryFirstName.touched && funeralCoverFormControls.beneficiaryFirstName.errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="funeralCoverFormControls.beneficiaryFirstName.errors.required">
                                                        Beneficiary First Name is required
                                                    </div>
                                                </div>
                                            </td>
                                            <td data-label="Last Name">
                                                <input type="text" class="form-control" formControlName="beneficiaryLastName" id="beneficiaryLastName"
                                                    [readOnly]="funeralCoverFormControls.acceptTerms.value" disabled>
                                                <div *ngIf="funeralCoverFormControls.beneficiaryLastName.touched && funeralCoverFormControls.beneficiaryLastName.errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="funeralCoverFormControls.beneficiaryLastName.errors.required">
                                                        Beneficiary Last Name is required
                                                    </div>
                                                </div>
                                            </td>
                                            <td data-label="Gender" class="gendercol">
                                                <select class="form-control" formControlName="beneficiaryGender" id="beneficiaryGender" 
                                                    disabled="disabled">
                                                    <option value="Male">Male</option>
                                                    <option value="Female">Female</option>
                                                </select>
                                                <div *ngIf="funeralCoverFormControls.beneficiaryGender.touched && funeralCoverFormControls.beneficiaryGender.errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="funeralCoverFormControls.beneficiaryGender.errors.required">
                                                        Beneficiary Gender is required
                                                    </div>
                                                </div>
                                            </td>
                                            <td data-label="Relationship" class="relation">
                                                <input type="text" class="form-control" formControlName="beneficiaryRelationship" id="beneficiaryRelationship"
                                                    [readOnly]="funeralCoverFormControls.acceptTerms.value" disabled>
                                                <div *ngIf="funeralCoverFormControls.beneficiaryRelationship.touched && funeralCoverFormControls.beneficiaryRelationship.errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="funeralCoverFormControls.beneficiaryRelationship.errors.required">
                                                        Beneficiary Relationship is required
                                                    </div>
                                                </div>
                                            </td>
                                            <td data-label="Date of Birth"> 
                                                <!--<input type="date" class="form-control" formControlName="beneficiaryDateOfBirth" id="beneficiaryDateOfBirth" max="{{dobUpto | date:'yyyy-MM-dd'}}"
                                                    [readOnly]="funeralCoverFormControls.acceptTerms.value"> -->
                                                    <div class="position-relative mb-wd">
                                                        <input  
                                                                formControlName="beneficiaryDateOfBirth"
                                                                id="beneficiaryDateOfBirth"
                                                                class="form-control"
                                                                placeholder="mm/dd/yyyy"                                   
                                                                ngbDatepicker
                                                                #calendar1="ngbDatepicker"
                                                                (click)="calendar1.toggle()"
                                                                (ngModelChange)="change('mainBeneficiaryDateOfBirth')"
                                                                max="{{dobUpto | date:'yyyy-MM-dd'}}"
                                                                [readOnly]="funeralCoverFormControls.acceptTerms.value"
                                                                [disabled]="funeralCoverFormControls.acceptTerms.value"
                                                                value="{{funeralCoverForm.value.beneficiaryDateOfBirth | date:'yyyy/MM/dd'}}" />
                                                        <button class="btn btn-outline-secondary bi bi-calendar3" id="btn-CalenderToggle2-Insurance" (click)="calendar1.toggle()" type="button" [disabled]="funeralCoverFormControls.acceptTerms.value" ></button>
                                                    </div>
                                                <div *ngIf="funeralCoverFormControls.beneficiaryDateOfBirth.touched && funeralCoverFormControls.beneficiaryDateOfBirth.errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="funeralCoverFormControls.beneficiaryDateOfBirth.errors.required">
                                                        Beneficiary Date Of Birth is required
                                                    </div>
             
                                                    <div *ngIf="funeralCoverFormControls.beneficiaryDateOfBirth.errors.incorrect">
                                                        {{funeralCoverFormControls.beneficiaryDateOfBirth.errors.message}}
                                                    </div>
                                                </div>
                                            </td>
                                            <td data-label="Age">
                                                 <input type="text" class="form-control" formControlName="beneficiaryAge" id="beneficiaryAge"
                                                    disabled> 
        
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="6"  class="border-0">
                                                <div *ngIf="funeralCoverFormControls.beneficiaryDateOfBirth.touched && funeralCoverFormControls.beneficiaryDateOfBirth.errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="funeralCoverFormControls.beneficiaryDateOfBirth.errors?.underAge">
                                                        Beneficiary needs to be 18 years and older, Please amend to proceed.
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr class=" bgch" *ngIf="funeralCoverFormControls.peopleCovered.value > 1">
                                            <th class="green" colspan="6">Additional Lives Insured</th>
                                        </tr>
                                        <tr formArrayName="beneficiaryOthers" *ngFor="let beneficiaryOther of beneficiaryOthersFormControls.controls; let i = index">
                                            <td data-label="First Name" scope="row" [formGroupName]="i">
                                                <input type="text" class="form-control"  formControlName="firstName"  id="{{'firstName'+i}}" 
                                                    [readOnly]="funeralCoverFormControls.acceptTerms.value" disabled>
                                                    <!-- [readOnly]="i < funeralCoverFormControls.beneficiaryOthersCount.value || funeralCoverFormControls.acceptTerms.value"> -->
                                                <div class="invalid-feedback"
                                                    *ngIf="beneficiaryOthersFormControls.controls[i].get('firstName').hasError('required') && beneficiaryOthersFormControls.controls[i].get('firstName').touched">
                                                    First Name is required
                                                </div>
                                            </td>
                                            <td data-label="Last Name" scope="row" [formGroupName]="i">
                                                <input type="text" class="form-control" formControlName="lastName" id="{{'lastName'+i}}" 
                                                    [readOnly]="funeralCoverFormControls.acceptTerms.value" disabled>
                                                <div class="invalid-feedback"
                                                    *ngIf="beneficiaryOthersFormControls.controls[i].get('lastName').hasError('required') && beneficiaryOthersFormControls.controls[i].get('lastName').touched">
                                                    Last Name is required
                                                </div>
                                            </td>
                                            <td data-label="Gender" class="gendercol" scope="row" [formGroupName]="i">
                                                <!-- attr.disabled]="funeralCoverFormControls.acceptTerms.value ? '' : null" -->
                                                <select class="form-control"  formControlName="gender" id="{{'gender'+i}}" 
                                                disabled="disabled">
                                                    <option value="Male">Male</option>
                                                    <option value="Female">Female</option>
                                                </select>
                                                <div class="invalid-feedback"
                                                    *ngIf="beneficiaryOthersFormControls.controls[i].get('gender').hasError('required') && beneficiaryOthersFormControls.controls[i].get('gender').touched">
                                                    Gender is required
                                                </div>
                                            </td>
                                            <td data-label="Relationship" class="relation" [formGroupName]="i">
                                                <input type="text" class="form-control" formControlName="relationship" id="{{'relationship'+i}}" 
                                                disabled>
                                                <div class="invalid-feedback"
                                                    *ngIf="beneficiaryOthersFormControls.controls[i].get('relationship').hasError('required') && beneficiaryOthersFormControls.controls[i].get('relationship').touched">
                                                    Relationship is required
                                                </div>
                                            </td>
                                            <td data-label="Date of Birth" [formGroupName]="i">
                                               <!-- <input type="date" class="form-control" formControlName="birthDate" max="{{dobUpto | date:'yyyy-MM-dd'}}" id="{{'birthDate'+i}}" 
                                                    [readOnly]="funeralCoverFormControls.acceptTerms.value"> -->
                                                    <div class="position-relative mb-wd">
                                                        <input  formControlName="birthDate"
                                                                id="{{'birthDate-'+i}}"
                                                                class="form-control"
                                                                placeholder="mm/dd/yyyy"
                                                                ngbDatepicker
                                                                #calendar="ngbDatepicker"
                                                                (click)="calendar.toggle()"
                                                                (ngModelChange)="change('birthDate-'+i)"
                                                                [readOnly]="funeralCoverFormControls.acceptTerms.value"
                                                                [disabled]="funeralCoverFormControls.acceptTerms.value"
                                                                max="{{dobUpto | date:'yyyy-MM-dd'}}" 
                                                                value="{{beneficiaryOther.value.birthDate | date:'MM/dd/yyyy'}}" />
                                                        <button class="btn btn-outline-secondary bi bi-calendar3" id="btn-CalenderToggle3-Insurance" (click)="calendar.toggle()" type="button" [disabled]="funeralCoverFormControls.acceptTerms.value"></button>
                                                    </div>                                                    
                                                <div class="invalid-feedback"
                                                    *ngIf="beneficiaryOthersFormControls.controls[i].get('birthDate').hasError('required') && beneficiaryOthersFormControls.controls[i].get('birthDate').touched">
                                                    Date of Birth is required
                                                </div>
                                                <div class="invalid-feedback"  *ngIf="beneficiaryOthersFormControls.controls[i].get('birthDate').hasError('incorrect')">
                                                  {{ beneficiaryOthersFormControls.controls[i].get('birthDate').errors.message }}
                                                </div>                                                                                  
                                            </td>
                                            <td data-label="Age" [formGroupName]="i">
                                                <input type="text"  class="form-control" formControlName="age" id="{{'age-'+i}}" disabled>
                                            </td>
                                   
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                             <!-- <button class="find-more btn mt-auto" (click)="addFieldValue()" 
                                *ngIf="(beneficiaryOthersFormControls.controls.length + 1) < funeralCoverFormControls.peopleCovered.value"
                                [attr.disabled]="(funeralCoverFormControls.acceptTerms.value) ? 'true' : null">+ Add more</button>  -->
                                 <div *ngIf="funeralCoverFormControls.beneficiaryOthers.touched && funeralCoverFormControls.beneficiaryOthers.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="funeralCoverFormControls.beneficiaryOthers.errors.required" >
                                        At least one additional Lives insured is required, please complete and submit.
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </div>
                     <div class="step-block">
                        <div class="d-flex justify-content-between flex-wrap align-items-center mb-2">
                            <h2 class="page-caption mb-0">Step 3: Accept the Policy</h2>
                            <!-- <button class="find-more btn mt-auto" (click)="callbackPopup('CalltoReduce')"> 
                                <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><defs><path d="M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z" id="call"/></defs><use fill="#fff" fill-rule="nonzero" xlink:href="#call"/></svg>
                                Call me to discuss
                            </button> -->
                            <div class="d-flex pt-md-0 pt-3 align-items-center">
                                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M26.6842 17.0834H29C29 11.3352 24.5155 7 18.5675 7V9.241C23.2708 9.241 26.6842 12.5386 26.6842 17.0834Z" fill="#64A346"/>
                                    <path d="M18.5791 13.723C21.0141 13.723 22.0527 14.7281 22.0527 17.0845H24.3685C24.3685 13.4709 22.3133 11.482 18.5791 11.482V13.723ZM22.5414 19.8219C22.3189 19.6262 22.0265 19.5218 21.726 19.5308C21.4255 19.5398 21.1403 19.6615 20.9307 19.8701L18.1599 22.6276C17.493 22.5044 16.1522 22.0999 14.772 20.7676C13.3918 19.4308 12.9738 18.1299 12.8499 17.489L15.6971 14.8065C15.913 14.6039 16.0389 14.3279 16.0482 14.037C16.0575 13.7461 15.9494 13.4631 15.7469 13.2479L11.4686 8.69532C11.266 8.47946 10.9844 8.34853 10.6837 8.33033C10.383 8.31212 10.0868 8.40809 9.85797 8.59783L7.34537 10.6831C7.14519 10.8775 7.02571 11.1363 7.00959 11.4103C6.99222 11.6904 6.66107 18.326 11.978 23.4736C16.6165 27.9612 22.4267 28.2895 24.0269 28.2895C24.2608 28.2895 24.4044 28.2828 24.4426 28.2806C24.7257 28.2652 24.993 28.1491 25.1929 27.9545L27.3465 25.5219C27.5428 25.3006 27.6421 25.014 27.6235 24.723C27.6049 24.432 27.4699 24.1595 27.247 23.9633L22.5414 19.8219Z" fill="#64A346"/>
                                    <circle cx="18" cy="18" r="17.5" stroke="#64A346"/>
                                </svg>
                                <div class="helptext">
                                    <span class="ndhelp d-block">Need Help?</span>
                                    <span class="calltext d-block"> Call us on <a id="anchor-CellNumber5-Insurance" class="catlink" href="tel:0872380652">087 2380 652</a></span>
                                </div>
                            </div>
                        </div>
                        <div class="bg-gry">
                                <div class="form-group d-flex flex-wrap mb-0 align-items-center">
                                    <input type="checkbox" id="input-ReadTerms3-Insurance" formControlName="readTerms" [checked]="funeralCoverFormControls.readTerms.value"
                                            [attr.disabled]="(isFuneralCoverAccepted || state == 'Accepted') ? 'true' : null">
                                    <label class="col-10 col-form-label ps-3">
                                        I have read or listened to and understand the <a id="anchor-FuneralDisclosure1-Insurance" href="javascript:void(0)" (click)="funeraldisclosures()" name="FuneralDisclosure">disclosures</a> and <a href="javascript:void(0)" id="anchor-Terms3-Insurance" (click)="terms()" name="T&C">T&#39;s&C&#39;s</a>	
                                    </label>
                                    <div class="col-12">
                                        
                                        <div *ngIf="funeralCoverFormControls.readTerms.touched && funeralCoverFormControls.readTerms.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="funeralCoverFormControls.readTerms.errors.pattern">
                                                Please read or listen to disclosures and T&#39;s&C&#39;s
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group d-flex mb-0 flex-wrap align-items-center">
                                    <input type="checkbox" id="input-Acceptterms3-Insurance" formControlName="acceptTerms" (change)="checkBoxClick($event)" 
                                            [attr.disabled]="(isFuneralCoverAccepted || state == 'Accepted' || toggleAcceptTerm) ? 'true' : null"/>
                                    <label class="col-10 col-form-label ps-3">
                                        I Accept
                                    </label>
                                    <div class="col-12">
                                        <div *ngIf="funeralCoverFormControls.acceptTerms.touched && funeralCoverFormControls.acceptTerms.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="funeralCoverFormControls.acceptTerms.errors.pattern">
                                                Acceptance is required
                                            </div>
                                        </div>
                                        <div class="step-block" *ngIf="!isFuneralCoverAccepted && state == 'Pending'">
                                            <div *ngIf="beneficiaryOthersFormControls.invalid ||(funeralCoverFormControls.beneficiaryFirstName.touched && funeralCoverFormControls.beneficiaryFirstName.errors) || 
                                                            (funeralCoverFormControls.beneficiaryLastName.touched && funeralCoverFormControls.beneficiaryLastName.errors) ||
                                                            (funeralCoverFormControls.beneficiaryGender.touched && funeralCoverFormControls.beneficiaryGender.errors) ||
                                                            (funeralCoverFormControls.beneficiaryRelationship.touched && funeralCoverFormControls.beneficiaryRelationship.errors) ||
                                                            (funeralCoverFormControls.beneficiaryDateOfBirth.touched && funeralCoverFormControls.beneficiaryDateOfBirth.errors); else EnableAcceptTerm">
                                                    <div class="invalid-feedback"
                                                        *ngIf="validationErrorMsg!=''||beneficiaryOthersFormControls.invalid|| funeralCoverFormControls.acceptTerms.errors.pattern || 
                                                        funeralCoverFormControls.readTerms.errors.pattern || funeralCoverFormControls.beneficiaryFirstName.errors.required ||
                                                        funeralCoverFormControls.beneficiaryLastName.errors.required || funeralCoverFormControls.beneficiaryGender.errors.required ||
                                                        funeralCoverFormControls.beneficiaryRelationship.errors.required || funeralCoverFormControls.beneficiaryDateOfBirth.errors.required 
                                                        || funeralCoverFormControls.beneficiaryDateOfBirth.errors?.underAge">
                                                        
                                                        <div *ngIf="!funeralCoverFormControls.acceptTerms.errors">
                                                            {{validationErrorMsg}}
                                                        </div>
                                                    </div>
                                            </div>    
                                            <ng-template #EnableAcceptTerm>{{enableAcceptTerm()}}</ng-template>
                    
                                                <div class="form-group row align-items-center">
                                                    <label class="col-sm-5 col-form-label">
                                                        
                                                    </label>
                                                    <div class="col-sm-7 text-md-start text-center">
                                                        <button type="submit" id="btn-SubmitFuneralCover-Insurance" name="SubmitFuneralCover" class="btn-custom" >Submit</button>
                                                    </div>
                                                </div>
                                            </div>
                                    </div>
                                </div>
                                
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</section>



<!--Credit Life Cover Disclosures popup-->
<ng-template #readDisclosures let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Credit Life Replacement Advice Summary</h4>
      
    </div>
    <div class="modal-body popAd insuremcealinks" >
     <h4>Policy type </h4>
        <p>
            The DebtBusters Credit Life Policy is a <strong>life insurance policy</strong> as defined in the Insurance Act and compulsory credit life insurance as defined in the <strong>National Credit Act (&#34;NCA&#34;)</strong>.
        </p>
        <hr/>
        <h4>Policy objective</h4>
        <p>
            Some credit providers require compulsory credit life insurance as a condition for credit (&#34;<strong>Compulsory Credit Life Insurance</strong>&#34;) in terms of the <strong>NCA</strong>.  The Policy&#39;s objective is to provide DebtBuster&#39;s clients with a single, consolidated Compulsory Credit Life Insurance product to replace the different Mandatory Credit Life Insurance policies they hold.
            <br/>
            This Policy is not an exact match of the policies it replaces, but it covers all Compulsory Credit Life Insurance your credit providers may insist on under the <strong>NCA</strong> and the requirements of your debt review process to provide efficient consolidated cover at a competitive rate.
            This Policy does <strong>not</strong> cover voluntary/optional credit life insurance. 
        </p>
        <hr/>
        <h4>Policy benefits</h4>
        <p>The Policy insures you and your credit providers who require Compulsory Credit Life Insurance from you   against your <strong> death, permanent disability, temporary disability, critical illness and loss of income</strong>.  
            </p>
       
        <p>We will pay out the <strong>Insured Sum</strong> below under the <strong>death, permanent disability</strong> and <strong>critical illness</strong> benefits in a lump sum.  We will pay out the <strong>Monthly Insured Sum</strong> under the <strong>temporary disability</strong> and <strong>loss of income</strong> benefits on a <strong>monthly  basis</strong> for a maximum period of <strong>12 months</strong>.</p>
        <div class="table-responsive">
            <table class="table table-sm">
                <thead>
                  <tr class="table-green">
                    <th scope="col">Insured Sum (lump sum)</th>
                    <th scope="col">Monthly Insured Sum
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>R {{creditLifeCoverFormControls.coverAmount.value}}</td>
                    <td>R {{creditLifeCoverFormControls.rehabCovered.value}}</td>
                  </tr>
                  
                </tbody>
              </table>
          </div>

        <hr/>
        <h4>We pay your credit providers first</h4>
        <p>
            You hereby assign and transfer the benefits in terms of this Policy to your Credit Providers listed below as security in terms of your Credit Agreements listed above. If we approve your claim, we will pay to your Credit Providers first.  If you owe less than the <strong>Insured Sum</strong>, we will pay the balance left over to you, your estate or your beneficiary.
        </p>
        <hr/>
        <h3 class="texthighlight"># Please note that the above numbers may fluctuate slightly after the debt review negotiations with your credit providers are finalised.</h3>
        <hr/>
        <h4>Limitations and exclusions</h4>
        <h5>Age limitations:  </h5>
        <ul>
          <li> <strong>Beneficiaries cannot be below the age: 18</strong> </li>
        </ul>
        <h5>Termination of benefits: </h5>
        <p>Your <strong>temporary and permanent disability and loss of income</strong> benefits end when you turn <strong>65</strong>.</p>
        <h5>Eligibility criteria</h5>
        <p>You do not qualify for the <strong>loss of income benefit</strong> if you are self-employed, your work is temporary, seasonal or casual or you are a contract worker with a contract term of less than <strong>12 months</strong>.
        </p>
        <h5>Waiting periods:</h5>
        <ul>
            <li>
                <strong>Loss of income benefit</strong>  <strong> 3 months</strong> from the claim event date less any waiting period you already served under any replaced policies.
            </li>
        </ul>
        <h5>Deferred periods: </h5>
        <ul>
            <li><strong>Temporary disability benefit</strong>:  <strong>1 month</strong> from the claim event date.</li>
            <li><strong>Loss of income benefit</strong>:  <strong>1 month</strong> from the claim event date.</li>
            <li  *ngIf="insurer == 'Hollard' || insurer == null || insurer == ''"><strong>Permanent disability benefit: 6 months</strong> from the claim event date. This is to determine if the disability is permanent.</li>
        </ul>
        <h5>Exclusions:</h5>
        <p>We will not pay a claim if the claim event is:</p>
        <ul>
            <li>
                <strong>For death, disability and critical illness:</strong>
                <ul>
                    <li>
                        Caused by self-harm, criminal acts, unscheduled flights, war, dangerous activities, alcohol or drugs or by not following prescribed medical treatment.
                    </li>
                    <li>
                        For a period of <strong>12 months </strong> from the start of the Policy, caused by a medical condition for which you received medical treatment <strong>12 months</strong> before you entered into the Policy <span *ngIf="insurer == 'Centriq'">(for Disability and Critical Illness)</span> or if you committed suicide within <strong>24 months </strong>  of the policy start date (Death).  This period will be reduced if you have already served such exclusion periods under any policies replaced by this Policy.
                    </li>
                </ul>
            </li>
            <li>
                <strong>For loss of income:</strong>
                <ul>
                    <li>Caused because you resign, retire or accept voluntary retrenchment or redundancy or were aware at the start of the Policy that you would; or you lost your job due to fraud or other misconduct or unlawful strike.</li>
                    <li>Due to maternity leave, partial reduction in income, confiscation of your employer&#39;s assets or if your work contact ends early.</li>
                </ul>
            </li>
        </ul>
        
        <hr/>
          <h4>Policy End Date</h4>
          <p>Your Policy ends on the earlier of the conclusion of your debt review or your death.
              <br/>
              Your Policy will <strong>renew</strong> automatically every year on the anniversary of the Policy start date, unless you don&#39;t want to renew.
          </p>
          <hr/>
          <h4>Beneficiary details</h4>
          <div class="table-responsive">
            <table class="table table-sm">
                <thead>
                  <tr class="table-green">
                    <th scope="col"> First name</th>
                    <th scope="col"> Last name</th>
                    <th scope="col">Relationship to main insured</th>
                    <th scope="col">Date of birth</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{creditLifeCoverFormControls.beneficiaryFirstName.value}}</td>
                    <td>{{creditLifeCoverFormControls.beneficiaryLastName.value}}</td>
                    <td>{{creditLifeCoverFormControls.beneficiaryRelationship.value}}</td>
                    <td>{{creditLifeCoverFormControls.beneficiaryDateOfBirth.value | date}}</td>
                  </tr>
                </tbody>
              </table>
          </div>

        <hr/>
        <h4>Replacement Record of advice</h4>
        <p>
            This is a summary of our recommendation to replace your various compulsory credit life policies with
the Policy. Please use this summary and the information given to you in the Quote about the policy to
help you decide if the Policy meets your needs and objectives for credit life cover and is the right one
for you. <br/>
Our recommendation is based on an analysis of the information we have about your current financial
circumstances and creditors as you enter debt review.
<br/>
A full record of the advice and information we provided to you in respect of the DebtBusters Credit
Life Policy (&#34;Policy&#34;) during our telephone call with you is available on request.
<br/>
A full <strong>Replacement Advice Record</strong> will be included in your policy pack.
        </p>
        <hr/>
        <h4>General information</h4>
        <div class="table-responsive">
            <table class="table table-sm">
                <thead>
                  <tr class="table-green">
                    <th scope="col"> </th>
                    <th scope="col">Name</th>
                    <th scope="col">ID</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Policy Holder</th>
                    <td>{{creditLifeCoverFormControls.firstName.value + ' ' + creditLifeCoverFormControls.lastName.value}}</td>
                    <td>{{creditLifeCoverFormControls.idNumber.value}}</td>
                  </tr>
                  <tr>
                    <th scope="row">Financial Advisor</th>
                    <td>{{creditLifeCoverFormControls.negotiator.value}}</td>
                    <td></td>
                  </tr>
                  <tr>
                    <th scope="row">Key Individual</th>
                    <td>Will-John Keevy</td>
                    <td>7611205067082</td>
                  </tr>
                  <tr>
                    <th scope="row">Financial Services Provider</th>
                    <td colspan="2">
                        JustMoney Protect (Pty) Ltd<br/>
                        2007/015303/07 <br/>
                        32984<br/>
                        4th Floor, Mutual Park, Jan Smuts Dr, Pinelands, Cape Town, 7405<br/>
                        <a href="tel:0860994096">086 099 4096</a>
                    </td>
                  </tr>
                </tbody>
              </table>
          </div>


          <hr/>

          <h4>Limited information and advice</h4>
          <p>
            Please note that we are only able to provide information and advice about the DebtBusters Credit Life
            Policy which is specifically designed for our DebtBusters&#39; clients. If you would like to compare credit
            life insurance from different insurers before you make a decision, please obtain advice from a
            financial services provider that offers a range of credit life products.
          </p>

          <hr/>

          <h4>Policy Benefits and Terms</h4>
          <p>The Policy covers you for death, critical illness and permanent disability (lump sum payments to
            settle your outstanding debt with each credit provider) and temporary disability and loss of income
            (Up to 12 monthly debt instalments for each credit provider).<br/>
            A summary of the insured amounts, premiums, benefits, exclusions, limitations, waiting periods and
term of the Policy is provided in your Quote. These are all in line with insurance and credit Regulations
and with what your credit providers may insist on to cover your credit agreements.
        </p>

        <hr/>

        <h4>Our Proposal</h4>
        <p>We recommend replacing your existing credit life policies the with the Policy mainly because:</p>
        <ul>
            <li>you will get all the compulsory credit life cover your credit providers may require from you
                under the law (NCA) at a more affordable price (this is especially important while you are
                under debt review)</li>
            <li>
                the Policy is a consolidation product which combines all your credit life cover into one policy
            </li>
            <li>
                it is much easier to deal with one insurer for all your credit life cover, especially in the event
of a claim.
            </li>
        </ul>

        <hr/>

        <h4>Affordability</h4>
        <p>We base our analysis on the information we have about your current financial circumstances as you
            enter debt review.</p>
        <p><strong>Compulsory Credit Life Insurance to be Replaced</strong></p>
        <div class="table-responsive">
            <table class="table table-sm">
                <tbody>
                  <tr *ngFor="let coveredAccount of creditLifeCoverFormControls.coveredAccounts.value" >
                    <td>{{coveredAccount.creditor}}</td>
                    <td>{{coveredAccount.accountNumber}}</td>
                    <td>{{coveredAccount.currentBalance}}</td>
                    <td>{{coveredAccount.currentInstallment}}</td>
                  </tr>
                </tbody>
              </table>
          </div>

        <hr>

        <h4>Policy Comparisons</h4>
        <p>A complete comparison between the Policy and the credit life policies we recommend that you
            replace is provided in the full <strong>Replacement Record of Advice</strong>.</p>

        <hr>
        <h4>Questions</h4>
        <p>If you have any questions about the advice we gave you, or if there is anything you don&#39;t understand,
            please contact us at <a href="tel:0860994096">086 099 4096</a> before you accept our advice.</p>   
            
        <hr>
        <h4>Declaration</h4>
        <p><strong>By accepting this Summary of Advice, you declare that:</strong></p>
        <ul>
            <li>The information you supplied to us is correct and complete and that you understand that, if not, it may
                affect the validity of the Policy or impact on your claims</li>
            <li>
                You understand the information given to you about the Policy telephonically
            </li>
            <li>
                You have read and understand the information about the Policy set out in the <strong>Quote</strong> and the <strong>General</strong>
<strong>Terms and Conditions</strong>, including the benefits, costs, exclusions and limitations, as well as important
disclosures
            </li>
            <li>You understand the advice given to you and that the advice is limited to the Policy</li>
            <li>You asked us where you didn&#39;t understand anything and that we answered your questions to your
                satisfaction</li>
            <li>
                You undertake to carefully read the full terms and conditions of your Policy as set out in your <strong>Policy
                    Schedule</strong> and <strong>Policy Terms and Conditions</strong> when you get these documents.
            </li>
            <li>You are aware that you have 31 days to cancel the Policy after receipt of your Policy documents.</li>
        </ul>  
        

    </div>
    <div class="modal-footer justify-content-center">
        <button type="button" name="OK" class="readDisclosures" id="btn-ReadDisclosures-Insurance" aria-label="Close" (click)="modal.dismiss('Cross click')">
            Ok
          </button>
    </div>
    
  </ng-template>

<!--life Cover Disclosures popup-->
  <ng-template #lifeDisclosures let-modal>
    <div class="modal-header">
      <h4 class="modal-title">LIFE COVER ADVICE SUMMARY</h4>
      
    </div>
    <div class="modal-body popAd insuremcealinks">
      <h4>Policy type</h4>
        <p>
            The DebtBusters Life Policy is a <strong>life insurance policy</strong> as defined in the <strong>Insurance Act</strong>, underwritten on a group basis. 
        </p>
        <hr/>
        <h4>Policy objective</h4>
        <p>It is designed to provide financial security to DebtBusters&#39; clients and their families in the unfortunate event of the client&#39;s death, permanent disability or critical illness.</p>
        <hr/>
        <h4>Policy benefits</h4>
        <p>The Policy insures you against the risk of <strong>death, permanent disability</strong> and <strong>critical illness</strong>. 
            <br/>
            The Policy also pays an <strong>immediate death</strong> benefit of 10% of the death benefit, limited to R25 000, to help your family cover immediate expenses related to your death.
            <br/>
            If we approve your claim, we will pay out the <strong>Insured Sum</strong> in a lump sum to you, your estate or your beneficiary.
        </p>
        <div class="table-responsive">
            <table class="table table-sm">
                <thead>
                  <tr class="table-green">
                    <th scope="col">Benefit type</th>
                    <th scope="col">Insured Sum</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Death</th>
                    <td>R {{lifeCoverFormControls.coverAmount.value}}</td>
                  </tr>
                  <tr>
                    <th scope="row">Permanent Disability</th>
                    <td>R {{lifeCoverFormControls.permanentDisabilityAmount.value}}</td>
                  </tr>
                  <tr>
                    <th scope="row">Critical illnessl</th>
                    <td>R {{lifeCoverFormControls.dreadDiseaseAmount.value}}</td>
                  </tr>
                  
                </tbody>
              </table>
          </div>
        <hr/>
        <h4>Limitations and exclusions</h4>
        <h5>Age limitations:  </h5>
        <ul>
          <li> <strong>Beneficiaries cannot be below the age: 18</strong> </li>
        </ul>
        <h5>Accelerated benefits</h5>
        <p> The <strong>permanent disability</strong> and <strong>critical illness</strong> benefits are accelerated benefits, which means that the amount we pay out for a successful claim will be deducted from the amount we pay out under the death benefit.
        </p>
        <h5>Termination of benefits</h5>
        <p>All your benefits end when you turn <strong>65</strong>.</p>
        <h5>Waiting periods and deferred periods</h5>
        <ul>
            <li><strong>Death benefit: 6 months</strong> from the policy inception date except for accidental death.</li>
            <li *ngIf="insurer == 'Centriq'"><strong>Permanent disability benefit: 6 months</strong> from the policy inception date except for accidental incidents.</li>
            <li  *ngIf="insurer == 'Hollard' || insurer == null || insurer == ''"><strong>Permanent disability benefit: 6 months</strong> from the claim event date. This is to determine if the disability is permanent.</li>
            <li><strong>Critical illness benefit: 6 months</strong> from the policy inception date.</li>
        </ul>
        <h5>Exclusions:</h5>
        <ul>
            <li>We will not pay for death, disability or illness caused by self-harm, criminal acts, unscheduled flights, war, dangerous activities, alcohol or drugs or by not following prescribed medical treatment.</li>
            <li>For a period of <strong>12 months </strong> from the start of the Policy, we will also not pay your claim if it is as a result of a medical condition for which you received treatment <strong>12 months</strong> before you entered into the Policy or if you committed suicide within <strong>24 months</strong> of the policy start date (Death).  This period will be reduced by such exclusion periods already served under any policy replaced by this Policy.</li>
        </ul>
        <hr/>

        <h4>Policy premium</h4>
        <div class="table-responsive">
            <table class="table table-sm">
                <tbody>
                  <tr>
                    <th class="col-md-6" scope="row">Premium per month</th>
                    <td>R {{lifeCoverFormControls.premium.value}}</td>
                  </tr>
                </tbody>
              </table>
          </div>
          <hr/>

          <h4>Policy End Date</h4>
          <p>Your Policy ends on your death.</p>
          <hr/>
          <h4>Advice Record Summary</h4>
        <p>
            This is a summary of our recommendation to purchase the Policy based on your objectives to obtain life insurance to protect yourself and your family financially in the unforeseen event of your death, permanent disability or critical illness. Please use this summary and the information given to you in the Quote about the policy to help you decide if the Policy meets your needs and objectives for life cover and is the right one for you. <br/>
<br/>
A full record of the advice we provided to you in respect of the DebtBusters Life Policy (&#34;Policy&#34;) during our telephone call with you is available on request.
<br/>
A full <strong>Advice Record</strong> will be included in your policy pack.
        </p>
        <hr/>
        <h4>General information</h4>
        <div class="table-responsive">
            <table class="table table-sm">
                <thead>
                  <tr class="table-green">
                    <th scope="col"> </th>
                    <th scope="col">Name</th>
                    <th scope="col">ID</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Policy Holder</th>
                    <td>{{lifeCoverFormControls.firstName.value + ' ' + lifeCoverFormControls.lastName.value}}</td>
                    <td>{{lifeCoverFormControls.idNumber.value}}</td>
                  </tr>
                  <tr>
                    <th scope="row">Financial Advisor</th>
                    <td>{{lifeCoverFormControls.negotiator.value}}</td>
                    <td></td>
                  </tr>
                  <tr>
                    <th scope="row">Key Individual</th>
                    <td>Will-John Keevy</td>
                    <td>7611205067082</td>
                  </tr>
                  <tr>
                    <th scope="row">Financial Services Provider</th>
                    <td colspan="2">
                        JustMoney Protect (Pty) Ltd<br/>
                        2007/015303/07 <br/>
                        32984<br/>
                        4th Floor, Mutual Park, Jan Smuts Dr, Pinelands, Cape Town, 7405<br/>
                        <a href="tel:0860994096">086 099 4096</a>
                    </td>
                  </tr>
                </tbody>
              </table>
          </div>


          <hr/>

          <h4>Limited information and advice</h4>
          <p>
            Please note that we are only able to provide information and advice about the DebtBusters Life Policy which is specifically designed for our DebtBusters&#39; clients. If you would like to compare life insurance from different insurers before you make a decision, please obtain advice from a financial services provider that offers life cover from a range of life insurers.
          </p>

          
        <hr>
        <h4>Our Proposal</h4>
        <p>Based on our mandate and after assessing your information, it is our view that your current life cover is not sufficient to meet your stated needs and objectives and we recommend that you obtain additional cover for death, permanent disability and critical illness to cover your debts which are not covered by credit life insurance and to provide financial security for you and your family if you are unable to bring in an income.<br/><br/>
            We recommend purchasing the Policy mainly because:
        </p>
        <ul>
            <li>The Policy provides you with a lump sum pay out in the unfortunate event of your death, disability and critical illness</li>
            <li>
                This money may be used to meet your stated objectives of settling your current outstanding debt obligations which are not covered by credit life insurance and providing financial security to you and your family.
            </li>
            <li>
                It is a much more cost-effective option to purchase life cover instead of credit life insurance to cover your remaining debts as life cover is age rated.
            </li>
            <li *ngIf="insurer == 'Centriq'">
                The Policy includes an immediate death benefit of 10% which can be used immediately to cover expenses such as your funeral
            </li>
            <li>
                In the event of your death, the Policy benefits will be paid directly to your nominated beneficiary and not get caught up in the winding up of your estate.
            </li>
        </ul>

        <hr>

        <h4>Affordability</h4>
        <p>We base our analysis on the information we have about your current financial circumstances as you enter debt review.
            <br/><br/>
            We have considered carefully what you can afford (after paying your debt review instalments), the amount of cover required to settle your uncovered debts, and any additional funds available for life insurance that may be applied to provide financial security to you and your family over and above covering your debts.
        </p>
        

        <hr>
        
        
        <h4>Declaration</h4>
        <p><strong>By accepting this Summary of Advice, you declare that:</strong></p>
        <ul>
            <li>The information you supplied to us is correct and complete and that you understand that, if not, it may affect the validity of the Policy or impact on your claims</li>
            <li>You understand the information given to you about the Policy telephonically</li>
            <li>
                You have read and understand the information about the Policy set out in the <strong>Quote</strong> and the <strong>General</strong>
<strong>Terms and Conditions</strong>, including the benefits, costs, exclusions and limitations, as well as important
disclosures
            </li>
            <li>You understand the advice given to you and that the advice is limited to the Policy</li>
            <li>You asked us where you didn&#39;t understand anything and that we answered your questions to your satisfaction</li>
            <li>
                You undertake to carefully read the full terms and conditions of your Policy as set out in your <strong>Policy
                    Schedule</strong> and <strong>Policy Terms and Conditions</strong> when you get these documents.
            </li>
            <li>You are aware that you have 31 days to cancel the Policy after receipt of your Policy documents.</li>
        </ul>  
      

    </div>
    <div class="modal-footer justify-content-center">
        <button type="button" name="OK" class="readDisclosures" id="btn-ReadDisclosures1-Insurance" aria-label="Close" (click)="modal.dismiss('Cross click')">
            Ok
          </button>
    </div>
    
  </ng-template> 

<!--Legal Insurence popup-->
<ng-template #legalInsurence let-modal>
    <div class="modal-header">
      <h4 class="modal-title">LEGAL INSURANCE POLICY</h4>
      
    </div>
    <div class="modal-body popAd insuremcealinks">
        <h4>Policy type</h4>
        <p>
            The DebtBusters Legal Insurance Policy is a <strong>non-life insurance policy</strong> as defined in the <strong class="text-green">Insurance Act</strong>.
        </p>
        <hr/>
        <div class="table-responsive">
            <table class="table table-sm">
                <thead>
                  <tr class="table-green">
                    <th scope="col" colspan="2">Insurer details</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colspan="2">The insurer of the Policies and the party that enters into the Policy contracts with you is Centriq Insurance</td>
                  </tr>
                  <tr>
                    <th scope="row">Postal address </th>
                    <td>PO Box 55674, Northlands, 2116</td>
                  </tr>
                  <tr>
                    <th scope="row">Tel  </th>
                    <td>(011) 268 6490</td>
                  </tr>
                  <tr>
                    <th scope="row">Email</th>
                    <td><a href="mailto:faiscomplaints@Centriq.co.za">faiscomplaints@Centriq.co.za</a> /<a href="mailto:complaints@Centriq.co.zaCentriq">complaints@Centriq.co.za</a></td>
                  </tr>
                  <tr>
                    <td colspan="2">
                        Centriq Insurance has outsourced certain services in connection with the Policies to <strong>JustMoney Protect</strong> (Pty) Ltd (“JustMoney Protect”),  another  member  of  the  IDM  Group.   They  are  your  contact  point  for  the  Policy.   If  you  have  any <strong>questions</strong>  about  a  Policy  before  you  decide  to  accept  the  Policy  contract,  we  will  be  happy  to  help.   You  can reach us via JustMoney Protect at:
                    </td>
                  </tr>
                  <tr>
                    <th>Tel</th>
                    <td>
                        <a href="tel:021 111 0705">021 111 0705</a>
                    </td>
                  </tr>
                  <tr>
                    <th>Email</th>
                    <td>
                        <a href="mailto:legal@jmprotect.co.za">legal@jmprotect.co.za</a>
                    </td>
                  </tr>
                </tbody>
              </table>
          </div>
          <br/>


        <h4>Policy objective</h4>
        <p>JustMoney Protect has recognised the fact that its DebtBusters clients are exposed to incidents where they would require the assistance of a legal professional. Unfortunately, as a result of the debt review process these clients have chosen to follow, very few have the financial ability to pay for legal services when they need it most, and that is a travesty.  The  Policy&#39;s  objective is  to  provide  DebtBusters&#39;  clients  with  an affordable  legal insurance  product  to protect their rights and interests, both during and after the debt review process. </p>
        <hr/>
        <h4>Policy benefits</h4>
        <p>The Policy insures you and your spouse have access to legal advice, assistance, and legal representation for civil, criminal, and labour-related matters. The purpose of the <strong>Policy</strong> is to help you cover legal expenses incurred as a result of legal matters that you may encounter in your daily personal life or arising during the debt review process as well as after clearance from debt review, if applicable.
            <br/>
            We will provide cover and pay Claims for legal expenses incurred as a result of Insured Matters occurring after the Policy Start Date to you, or in respect of you and your partner, in South Africa for the duration of the Policy Term, subject at all times to payment of the Premiums due in terms of this Policy.
            <br/><br/>
            Payment of such Claims, subject to the <strong class="text-green">Insured Sum</strong> on the policy summary, will be paid directly to the legal service provider/attorney firm who has been duly approved and appointed by JustMoney Protect to represent you in a legal matter. 
        </p>
        <div class="table-responsive">
            <table class="table table-sm">
                
                <tbody>
                  <tr >
                    <th class="table-green" scope="row">Maximum Cover per single claim event</th>
                    <td>{{legalCoverFormControls.maximumBenefitAmountPerInsuredMatter.value | currency:'R ': 'symbol' : '1.0-0'}} </td>
                  </tr>
                  <tr >
                    <th class="table-green" scope="row">Annual Cover</th>
                    <td>{{legalCoverFormControls.annualLimit.value | currency:'R ': 'symbol' : '1.0-0'}} </td>
                  </tr>
                  <tr>
                    <th class="table-green" scope="row">Life Time Cover</th>
                    <td>{{legalCoverFormControls.overallBenefitLimit.value | currency:'R ': 'symbol' : '1.0-0'}} </td>
                  </tr>
                  <tr>
                    <th class="table-green" scope="row">Maximum number of Criminal Cases</th>
                    <td>3</td>
                  </tr>
                  
                </tbody>
              </table>
          </div>
        <h4>Limitations and exclusions</h4>
        <h5>Termination of benefits:</h5>
        <p>Your legal insurance benefits end upon non-payment of premium or cancellation by JustMoney Protect or you.</p>
        
        <h5>Eligibility criteria:</h5>
        <ul>
            <li>You must be 18 years of age or older; and</li>
            <li>You must be a permanent lawful South African citizen who ordinarily resides in South Africa; or</li>
            <li>You must be a permanent resident of South Africa residing permanently and lawfully in South Africa.</li>
        </ul>
        <hr/>

        <h5>Exclusions:</h5>
        <p>We will not pay a claim if the claim event is:</p>
        <ul>
            <li><strong>A pre-existing matter or event;</strong></li>
            <li><strong>Charges or potential charges for Violent Criminal Offenses of which you are accused; or</strong></li>
            <li><strong>An event which originated outside the borders of South Africa.</strong></li>
        </ul>
        <hr/>

        <h4>Policy premium</h4>
        <div class="table-responsive">
            <table class="table table-sm">
                <tbody>
                  <tr>
                    <th class="col-md-6" scope="row">Total premium for legal insurance</th>
                    <td>{{legalCoverFormControls.premium.value  | currency:'R ': 'symbol' : '1.2-2'}}  per month</td>
                  </tr>
                </tbody>
              </table>
          </div>

          <h4>Policy End Date</h4>
          <p>Your policy ends when your policy lapses due to non-payment of your premium, gets cancelled by either party or on your death. Your Policy will <strong>renew</strong> automatically every year on the anniversary of the Policy start date, unless you don&#39;t want to renew.</p>
          <hr/>
          
        <h4 *ngIf="legalCoverFormControls.spouseFirstName.value != null && legalCoverFormControls.spouseDOB.value != null">Spouse Insured</h4>
        <div *ngIf="legalCoverFormControls.spouseFirstName.value != null && legalCoverFormControls.spouseDOB.value != null" class="table-responsive">
            <table class="table table-sm">
                <thead>
                    <tr class="table-green">
                        <th scope="col">Full name</th>
                        <th scope="col">Date of birth</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">{{ legalCoverFormControls.spouseFirstName.value }} {{ legalCoverFormControls.spouseLastName.value }}</th>
                        <td>{{ legalCoverFormControls.spouseDOB.value }}  </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="modal-footer justify-content-center">
        <button type="button" name="OK" class="readDisclosures" id="btn-ReadDisclosures2-Insurance" aria-label="Close" (click)="modal.dismiss('Cross click')">
            Ok
          </button>
    </div>
    
  </ng-template> 

  <!--Funeral Cover Disclosures popup-->
  <ng-template #funeralDisclosures let-modal>
    <div class="modal-header">
      <h4 class="modal-title">FUNERAL ADVICE SUMMARY</h4>
      
    </div>
    <div class="modal-body popAd insuremcealinks">
        <h4>Policy type</h4>
        <p>The Policy is a <strong>funeral policy</strong> as defined in the <strong>Insurance Act</strong>.</p>
        <hr/>
        <h4>Policy objective</h4>
        <p>It is designed to provide funeral cover that pays a specific amount of money in the event of death ensuring that the cost of a funeral will be covered so that our DebtBusters&#39; clients and their families will not have to struggle financially in this difficult time.</p>
        <hr/>
        <h4>Policy benefits</h4>
        <p>This Policy insures the insured lives under the Policy against the risk of <strong>death</strong>.
            <br/>
            If an insured life dies, we will pay out the Insured Sum shown below, subject to the age limitations shown below.  If you die, we will pay it to your estate or your appointed beneficiary.  If another member of the Policy dies, we will pay it out to you.
        </p>
        <p><strong>Plan option chosen</strong></p>
        <div class="table-responsive">
            <table class="table table-sm">
                <tbody>
                  <tr>
                    <th class="col-md-6" scope="row">Plan option</th>
                    <td>{{funeralCoverFormControls.funeralScheme.value}}</td>
                  </tr>
                  <tr>
                    <th scope="row">Maximum number of insured lives</th>
                    <td>{{funeralCoverFormControls.peopleCovered.value}}</td>
                  </tr>
                </tbody>
              </table>
          </div>
        <p>The table below shows the insured lives you have chosen to cover under this Policy.</p>  
        <div class="table-responsive">
            <table class="table table-sm">
                <thead>
                  <tr class="table-green">
                    <th scope="col">Member First Name</th>
                    <th scope="col">Member Last Name</th>
                    <th scope="col">Relation</th>
                    <th scope="col">Member DOB</th>
                    <th scope="col">Member Age</th>
                    <th scope="col">Gender</th>
                    <th scope="col">Insured Sum</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row" colspan="6">Main insured life</th>
                  </tr>
                  <tr>
                    <td>{{funeralCoverFormControls.beneficiaryFirstName.value}}</td>
                    <td>{{funeralCoverFormControls.beneficiaryLastName.value}}</td>
                    <td>{{funeralCoverFormControls.beneficiaryRelationship.value}}</td>
                    <td>{{funeralCoverFormControls.beneficiaryDateOfBirth.value | date}}</td>
                    <td>{{calculateAge(funeralCoverFormControls.beneficiaryDateOfBirth.value)}}</td>
                    <td>{{funeralCoverFormControls.beneficiaryGender.value}}</td>
                    <td>R {{funeralCoverFormControls.coverAmount.value}}</td>
                  </tr>
                  <tr>
                    <th scope="row" colspan="6">Other insured lives</th>
                  </tr>
                  <tr *ngFor="let beneficiaryOther of funeralCoverFormControls.beneficiaryOthers.value">
                    <td>{{beneficiaryOther.firstName}}</td>
                    <td>{{beneficiaryOther.lastName}}</td>
                    <td>{{beneficiaryOther.relationship}}</td>
                    <td>{{beneficiaryOther.birthDate | date}}</td>
                    <td>{{calculateAge(beneficiaryOther.birthDate)}}</td>
                    <td>{{beneficiaryOther.gender}}</td>
                    <td>R {{funeralCoverFormControls.coverAmount.value}}</td>
                  </tr>
                </tbody>
              </table>
          </div>

          <hr/>
          <h4>Limitations and exclusions</h4>
          <h5>Age limitations:  </h5>
          <ul>
              <li>
                  <strong>Maximum Insured Sum:</strong>
                  <ul>
                      <li>Members <strong>under 6</strong>:  <strong>R10 000</strong></li>
                      <li>Members between <strong>6 and 14</strong>:  <strong>R20 000</strong></li>
                  </ul>
              </li>
              <li>
                  <strong>Benefit termination age:  99</strong>
              </li>
              <li>
                  <strong>Beneficiaries cannot be below the age: 18</strong>
              </li>
          </ul>
          <h5>Waiting periods:</h5>
          <p>Only <strong>natural death</strong>:  <strong>6 months</strong> from the start of the Policy <strong>less</strong> any waiting period served under an existing funeral policy. </p>

          <h5>Deferred periods:</h5>
          <p>None</p>

          <h5>Exclusions:</h5>
          <p>
            We will not pay a claim if the insured life&#39;s death is: 
          </p>
          <ul>
              <li>caused by self-harm, criminal acts, unscheduled flights, war, dangerous activities, alcohol or drugs or by not following prescribed medical treatment.</li>
              <li>for the first <strong>12 months</strong> of the Policy (<strong>less</strong> any waiting period served under an existing funeral policy), caused by suicide.</li>
          </ul>

          <hr/>
          <h4>Policy premium</h4>
            <div class="table-responsive">
                <table class="table table-sm">
                    <tbody>
                    <tr>
                        <th class="col-md-6" scope="row">Premium per month</th>
                        <td>R {{funeralCoverFormControls.premium.value}}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <hr/>

            <h4>Policy End Date</h4>
            <p>Your Policy ends on your death.</p>
            <hr/>
            <h4>Advice Record Summary</h4>
        <p>
            This is a summary of our recommendation to purchase the Policy based on your objective to obtain funeral cover to ensure that you and your family are not faced with the sudden financial burden caused by funeral costs, especially during the time that you are under debt review. You would like us to utilise any additional affordability remaining in your debt review instalments towards this need.  Please use this summary and the information given to you in the Quote about the policy to help you decide if the Policy meets your needs and objectives for funeral cover and is the right one for you.
            <br/>
            A full record of the advice we provided to you in respect of the DebtBusters Funeral Policy (&#34;Policy&#34;) during our telephone call with you is available on request.
<br/>
A full <strong>Advice Record</strong> will be included in your policy pack.
        </p>
        <hr/>
        <h4>General information</h4>
        <div class="table-responsive">
            <table class="table table-sm">
                <thead>
                  <tr class="table-green">
                    <th scope="col"> </th>
                    <th scope="col">Name</th>
                    <th scope="col">ID</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Policy Holder</th>
                    <td>{{funeralCoverFormControls.firstName.value + ' ' + funeralCoverFormControls.lastName.value}}</td>
                    <td>{{funeralCoverFormControls.idNumber.value}}</td>
                  </tr>
                  <tr>
                    <th scope="row">Financial Advisor</th>
                    <td>{{funeralCoverFormControls.negotiator.value}}</td>
                    <td></td>
                  </tr>
                  <tr>
                    <th scope="row">Key Individual</th>
                    <td>Will-John Keevy</td>
                    <td>7611205067082</td>
                  </tr>
                  <tr>
                    <th scope="row">Financial Services Provider</th>
                    <td colspan="2">
                        JustMoney Protect (Pty) Ltd<br/>
                        2007/015303/07 <br/>
                        32984<br/>
                        4th Floor, Mutual Park, Jan Smuts Dr, Pinelands, Cape Town, 7405<br/>
                        <a href="tel:0860994096">086 099 4096</a>
                    </td>
                  </tr>
                </tbody>
              </table>
          </div>


          <hr/>

          <h4>Limited information and advice</h4>
          <p>
            Please note that we are only able to provide information and advice about the DebtBusters Funeral Policy which is specifically designed for our DebtBusters&#39; clients. If you would like to compare funeral policies from different insurers before you make a decision, please obtain advice from a financial services provider that offers funeral cover from a range of life insurers.
          </p>

        <hr>
        <h4>Our Proposal</h4>
        <p>Based on our mandate and after assessing your financial situation, we recommend that you obtain funeral insurance to help you provide emergency funding if you or one of your family members die.
            <br/><br/>
            We recommend purchasing the Policy mainly because:
        </p>
        <ul>
            <li>Given that you are under debt review and financially vulnerable, it is especially important for you to secure funeral cover for yourself and your family, as you are working within your debt review budget and are unlikely to have access to emergency funding like funeral costs</li>
            <li>The Policy provides you with peace of mind that you will have immediate access to funds to cover funeral costs on multiple lives.  You can choose how many lives you want to cover.</li>
            <li>You have sufficient funds available in your debt review budget to afford the Policy.</li>
        </ul>

        <hr>

        <h4>Affordability</h4>
        <p>We have considered carefully what you can afford to allocate towards funeral insurance (after paying your debt review instalments) and the funeral cover you would require.</p>
        
        
        <hr>
        <h4>Declaration</h4>
        <p><strong>By accepting this Summary of Advice, you declare that:</strong></p>
        <ul>
            <li>The information you supplied to us is correct and complete and that you understand that, if not, it may affect the validity of the Policy or impact on your claims</li>
            <li>You understand the information given to you about the Policy telephonically</li>
            <li>
                You have read and understand the information about the Policy set out in the <strong>Quote</strong> and the <strong>General</strong>
<strong>Terms and Conditions</strong>, including the benefits, costs, exclusions and limitations, as well as important
disclosures
            </li>
            <li>You understand the advice given to you and that the advice is limited to the Policy</li>
            <li>You asked us where you didn&#39;t understand anything and that we answered your questions to your satisfaction</li>
            <li>
                You undertake to carefully read the full terms and conditions of your Policy as set out in your <strong>Policy
                    Schedule</strong> and <strong>Policy Terms and Conditions</strong> when you get these documents.
            </li>
            <li>You are aware that you have 31 days to cancel the Policy after receipt of your Policy documents.</li>
        </ul> 
    </div>
    <div class="modal-footer justify-content-center">
        <button type="button" name="OK" class="readDisclosures" id="btn-ReadDisclosures3-Insurance" aria-label="Close" (click)="modal.dismiss('Cross click')">
            Ok
          </button>
    </div>
  </ng-template>  


  <ng-template #mainInsuredDetail let-modal>
    <button type="button" class="close noheaderclose" id="btn-ModalDismiss-Insurance" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span id="btn-ModalDismiss-Insurance-Span1" aria-hidden="true">
            <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><defs><path id="noheadercl" d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></defs><use fill="#fff" fill-rule="nonzero" xlink:href="#noheadercl"/></svg></span>
      </button>
    <div class="insuremcealinks">
        <table class="table table-sm">
            <thead>
              <tr class="table-green">
                <th scope="col" colspan="2">Policyholder and main insured details (applicable to all Policies)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">Name</th>
                <td>{{firstName}}</td>
              </tr>
              <tr>
                <th scope="row">Surname</th>
                <td>{{lastName}}</td>
              </tr>
              <tr>
                <th scope="row">Date of Birth</th>
                <td>{{birthDate | date}}</td>
              </tr>
              <tr>
                <th scope="row">Identity Number</th>
                <td>{{idNumber}}</td>
              </tr>
              <tr>
                <th scope="row">Current Occupation</th>
                <td>{{occupation}}</td>
              </tr>
              <tr class="table-green" *ngIf="spouseFirstName != null && spouseLastName != null && spouseIdNumber != null" >
                <th *ngIf="spouseFirstName != '' && spouseLastName != '' && spouseIdNumber != ''" scope="col" colspan="2">Second life insured details (only applicable to Credit Life Policy)</th>
              </tr>
              <tr  *ngIf="spouseFirstName != null && spouseFirstName !=''">
                <th scope="row">Name</th>
                <td>{{spouseFirstName}}</td>
              </tr>
              <tr *ngIf="spouseLastName != null && spouseLastName !=''">
                <th scope="row">Surname</th>
                <td>{{spouseLastName}}</td>
              </tr>
              <tr *ngIf="spouseIdNumber != null && spouseIdNumber !=''">
                <th scope="row">Date of Birth</th>
                <td>{{getDateOfBirthFromSAID(spouseIdNumber) | date}}</td>
              </tr>
              <tr *ngIf="spouseIdNumber != null && spouseIdNumber !=''">
                <th scope="row">Identity Number</th>
                <td>{{spouseIdNumber}}</td>
              </tr>
              <tr class="table-green">
                <th scope="col" colspan="2">Insurer details</th>
              </tr>


              <tr>
                <th colspan="2">The insurer of the Policies and the party that enters into the Policy
                    contracts with you is 
                    <span  *ngIf="insurer == 'Centriq'" class="txt-md">Centriq</span>
                    <span   *ngIf="insurer == 'Hollard' || insurer == null || insurer == ''" class="txt-md">Hollard</span>.</th>
              </tr>
              <tr>
                <th scope="row">Postal address</th>
                <td>
                    <span  *ngIf="insurer == 'Centriq'" class="txt-md">PO Box 55674, Northlands, 2116</span>
                    <span   *ngIf="insurer == 'Hollard' || insurer == null || insurer == ''" class="txt-md">PO Box 87419, Houghton, 2041</span>
                     
                </td>
              </tr>
              <tr>
                <th scope="row">Tel</th>
                <td>
                    <span  *ngIf="insurer == 'Centriq'" class="txt-md">(011) 268 6490</span>
                    <span   *ngIf="insurer == 'Hollard' || insurer == null || insurer == ''" class="txt-md">0860 666 675</span>
                </td>
              </tr>
              <tr>
                <th scope="row">Email</th>
                <td>
                    <a *ngIf="insurer == 'Hollard' || insurer == null || insurer == ''" id="anchor-mycomplaint-HollardInsurance" href="mailto:mycomplaint@hollard.co.za">mycomplaint@hollard.co.za</a>
                    <a *ngIf="insurer == 'Centriq'" id="anchor-MailToFaisComplaints-Insurance" href="mailto:faiscomplaints@Centriq.co.za">faiscomplaints@Centriq.co.za</a>
                    <br *ngIf="insurer == 'Centriq'"/>
                    <a *ngIf="insurer == 'Centriq'" id="anchor-MailToComplaints-Insurance" href="mailto:complaints@Centriq.co.za">complaints@Centriq.co.za</a>
                    
                </td>
              </tr>
              <tr>
                <th colspan="2">
                    <p>
                        <span  *ngIf="insurer == 'Centriq'" class="txt-md">Centriq</span>
                        <span   *ngIf="insurer == 'Hollard' || insurer == null || insurer == ''" class="txt-md">Hollard</span>
                        has outsourced certain services in connection with the
                        Policies to JustMoney Protect (Pty) Ltd (&#34;JustMoney Protect&#34;), another member
                        of the IDM Group. They are your contact point for the Policies. If
                        you have any questions about a Policy before you decide to accept
                        the Policy contract, we will be happy to help. You can reach us via
                        JustMoney Protect at:</p>
                </th>
              </tr>
              <tr>
                <th scope="row">Tel</th>
                <td>(086) 099 4096</td>
              </tr>
              <tr>
                <th scope="row">Email</th>
                <td><a id="anchor-MailToInquiries-Insurance" href="mailto:enquiries@jmprotect.co.za">enquiries@jmprotect.co.za</a></td>
              </tr>
            </tbody>
           
          </table>
      </div>
  </ng-template>

  <!---terms popup-->
  <ng-template #termPop let-modal>
    <div class="modal-header">
      <h4 class="modal-title">GENERAL TERMS &amp; CONDITIONS FOR ALL POLICIES</h4>
      
    </div>
    <!-- [innerHTML]="termsAndConditionHtml" -->
    <div class="modal-body popAd insuremcealinks">
        <h4>Cooling off right</h4>
        <p>
            You have <strong class="text-green">31 days</strong> after you receive your Policy documents to <strong>cancel</strong> your Policy if you no longer want it unless you have already claimed under the Policy.  We will refund any premiums you have paid.
        </p>
        <hr/>
        <h4>Premiums</h4>
        <p>
            Premiums are payable monthly in arrears.  Your monthly premium for each Policy is set out in the Quotes.  Premiums will be collected as part of your debt review instalments until the end of the debt review, after which we will collect your premiums by debit order.<br/><br/>
            If you <strong>don&#39;t pay</strong> your premium on time, we will give you <strong class="text-green">1-month</strong> grace to pay.   If you don&#39;t pay during this grace period, you must pay a double premium by the next premium due date. If you still haven&#39;t paid your missed premiums within <strong  class="text-green">15 days</strong>, your Policy will lapse. <br/> <br/>
            We will <strong>review</strong> your Policy annually and may increase your premiums if your premiums are no longer sufficient to maintain  the  Policy  benefits.   We  will  notify  you  <strong  class="text-green">31  days</strong>  in  advance  and  explain  the  reasons  why  an  increase  is necessary.  You have the right to terminate your Policy if you don&#39;t accept the premium increase.
        </p>
        <hr/>
        <h4>Policy Term</h4>
        <p>
            Your  Policy  begins  when  you  accept  the  Policy  and  ends  on  the <strong class="text-green">&#39;Policy  End  Date&#39;</strong>  specified  for  each  Policy  in  the Quote.<br/><br/>
            The Policy also ends if
        </p>
        <ul>
            <li>you or we <strong>terminate</strong> it before the Policy End Date by giving each other <strong class="text-green">31 days&#39;</strong> notice</li>
            <li>you no longer meet the Policy&#39;s <strong>eligibility criteria</strong>, such as being a permanent South African resident</li>
            <li>you <strong>die</strong>.</li>
        </ul>
        <h4>Conflicts of Interest</h4>
        <p>
            JustMoney Protect is a registered financial services provider that provides advice and information to you about the Policies.  JustMoney Protect also administers the Policies on Centriq Insurance's and Centriq Life Insurance's behalf. 
            <br/><br/>
            We manage any potential <strong>conflicts of interest</strong> because JustMoney Protect acts on both our and your behalf in terms of our and  JustMoney Protect&#39;s  <strong class="text-green">Conflicts  of  Interest  Management  Policies</strong>  and  by  making  sure  we  comply  with  regulation  that applies  to  the  management  of  conflicts  of  interest.   You  can  access  a  copy  of  JustMoney Protect&#39;s  <strong class="text-green">Conflicts  of  Interest Management    Policy</strong>    at <a href="https://idmprotect.co.za/wp-content/uploads/2021/06/IDM-Protect-Policy-Updated-June-2021.pdf">https://justmoneyprotect.co.za/wp-content/uploads/2021/06/IDM-Protect-Policy-Updated-June-2021.pdf</a>.
        </p>
        <h4>Commission and Fees</h4>
        <p>
            Centriq Insurance and Centriq Life pay JustMoney Protect the following <strong>commission</strong> for selling the Policy and providing intermediary services to you about the Policy:
        </p>


        <div class="table-responsive">
            <table class="table table-sm">
                
                <tbody>
                  <tr>
                    <th scope="row">Credit Life and Life Policies</th>
                    <td><strong class="text-green">3.25%</strong> of your Policy premium each month</td>
                  </tr>
                  <tr>
                    <th scope="row">Funeral Policy</th>
                    <td><strong class="text-green">7.5%</strong> of your Policy premium each month</td>
                  </tr>
                  <tr>
                    <th scope="row">Legal Insurance Policy</th>
                    <td><strong class="text-green"> 20%</strong> of your Policy premium each month</td>
                  </tr>
                  
                </tbody>
              </table>
              <p>
                JustMoney Protect also performs certain insurance functions on behalf of Centriq for which Centriq pays JustMoney Protect a <strong>binder fee</strong> of up to <strong class="text-green">9%</strong> of your Policy premiums each month.
                <br/><br/>
                None of these charges are added to your Policy premiums.
              </p>
          </div>


          <hr/>

          <h4>Telephone recordings</h4>
          <p>
            You have the right to request a recording of any <strong>telephonic disclosures</strong> we made to you in connection with the Policies
          </p>

          <hr/>

          <h4>POPI</h4>
          <p>
            Please read Centriq Insurance, Centriq Life Insurance, JustMoney Protect and JustMoney’s privacy notices. Please find links below:
            <br/>
            <a href="https://www.centriq.co.za/wpcontent/uploads/2021/06/Centriq-Privacy-Notice.pdf"> Centriq Link</a> <br/>
            <a href="https://idmprotect.co.za/wp-content/uploads/2021/06/IDM-Protect-Policy-Updated-June-2021.pdf"> JustMoney Protect Link </a> <br/>
            <a href="https://justmoneycreditsav.co.za/policy "> JustMoney Link</a>
        </p>

        <hr>
        <h4>Ongoing financial services</h4>
        <p>JustMoney Protect would like to use your personal information to keep you information about other financial products and services which may be of particular interest to you.
            <br/>
            Please click here <a href="mailto:enquiries@jmprotect.co.za">enquiries@jmprotect.co.za</a> <strong> or</strong> contact us at <a href="tel:0860994096">0860 994 096</a>  if you do not want to receive such information. You may opt out at any time if you don&#39;t want to receive any further communications of this nature by contacting us at: <a href="tel:0860994096">0860 994 096</a>
        </p>
        
        <hr>
        <h4>Complaints</h4>
        <p class="mb-0">If you have any complaints about any of your Policies or our services, please contact <strong>JustMoney Protect</strong> at:</p>
        
        <div class="d-flex row flex-wrap">
            <div class="col-md-3 detali">Address:</div>
            <div class="col-md-9 detali">4th Floor, Mutual Park, Jan Smuts Dr, Pinelands, Cape Town, 7405</div>
        </div>
        <div class="d-flex  row flex-wrap">
            <div class="col-md-3 detali">Tel:</div>
            <div class="col-md-9 detali"><a href="tel:0860994096">0875376419</a></div>
        </div>
        <div class="d-flex  row flex-wrap">
            <div class="col-md-3 detali">Email:</div>
            <div class="col-md-9 detali"><a href="mailto:complaints@jmprotect.co.za">complaints@jmprotect.co.za</a></div>
        </div>
        <p class="mt-3 mb-0">If JustMoney Protect does not resolve the matter to your satisfaction, you can contact:</p>
        
        <table class="table-responsive">
            <table class="table table-sm">
                <tbody>
                    <tr>
                        <th scope="row"><strong>Centriq Life Insurance</strong> for Credit Life, Life or Funeral policies </th>
                        <th scope="row"><strong>Centriq Insurance</strong> for Legal Insurance policy</th>
                    </tr>
                    <tr>
                        <td scope="row">
                            <strong>Tel: </strong> <a href="tel:(011) 268 6490Email">(011) 268 6490</a> <br/>
                            <strong>Email: </strong> <a href="mailto:faiscomplaints@centriq.co.za">faiscomplaints@centriq.co.za</a> or <a href="mailto:complaints@centriq.co.za ">complaints@centriq.co.za</a>
                        </td>
                        <td scope="row">
                            <strong>Tel: </strong> <a href="tel:(011) 268 6490Email">(011) 268 6490</a> <br/>
                            <strong>Email: </strong> <a href="mailto:faiscomplaints@centriq.co.za">faiscomplaints@centriq.co.za</a> or <a href="mailto:complaints@centriq.co.za ">complaints@centriq.co.za</a>
                        </td>
                        
                    </tr>
                    <tr>
                        <td colspan="2">
                            <p>If your complaint is not resolved to your satisfaction, you can submit the complaint to the <strong class="text-green"> National Financial Ombud Scheme of South Africa for Long-Term and Short-Term Insurance </strong> at:</p>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            <strong>JHB Physical Address: </strong> 110 Oxford Road, Houghton Estate, Illovo, Johannesburg, 2198<br/>
                            <strong>CPT Physical Address: </strong> Claremont Central Building, 6th Floor, 6 Vineyard Road,Claremont, 7708<br/>
                            <strong>Tel:  </strong > <a href="tel:0860-800-900">0860-800-900</a><br/>
                            <strong>Email: </strong> <a href="mailto:info@nfosa.co.za">info@nfosa.co.za</a><br/>     
                            <strong>Website: </strong> <a href="http://www.nfosa.co.za/">http://www.nfosa.co.za/</a><br/>
                        </td>
                    </tr>
                    
                </tbody>
            </table>
        </table>        
  
    </div>
    <div class="modal-footer justify-content-center">
        <button type="button" name="OK" id="btn-ReadDisclosures4-Insurance" class="readDisclosures" aria-label="Close" (click)="modal.dismiss('Cross click')">
            Ok
          </button>
    </div>
  </ng-template>  



  <ng-template #callbackConfirmationModel let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Free callback request</h4>
      <button type="button" id="btn-ModalDismiss1-Insurance" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span id="btn-ModalDismiss1-Insurance-Span1" aria-hidden="true"><svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><defs><path id="noheadercl" d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></defs><use fill="#fff" fill-rule="nonzero" xlink:href="#noheadercl"/></svg></span>
      </button>
    </div>
    <div class="modal-body insuremcealinks">
      <div *ngIf="callbackErrorMsg!=''" class="alert alert-success" role="alert">
        {{callbackErrorMsg}}
      </div>
      <div *ngIf="callbackSuccessMsg!=''" class="alert alert-success" role="alert">
        {{callbackSuccessMsg}}
      </div>
      <div class="txthd" *ngIf="callbackErrorMsg==''">Do you want a Financial Assessor to call you now?</div>
    </div>
    <div class="modal-footer" *ngIf="callbackErrorMsg==''">
      <button type="button" id="btn-ModalDismissNo-Insurance" class="btn-border" (click)="modal.dismiss('No')">No</button>
      <button type="button" class="btn btn-primary" id="btn-CallBackCampaign-Insurance" (click)="callback(campaignSourceToModel)">Yes</button>
    </div>
  </ng-template>